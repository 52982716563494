import Button from 'components/button/button.component'
import useCreateOrganisation from './create-organisation.service'
import { FormikProvider } from 'formik'
import { stepCreateOrg } from '../organisation-details/organisation-details.static'
import ProgressionStep from 'components/progression-step/progression-step.component'

const CreateOrganisation = () => {
    const {
        formik,
        allModal,
        stepFilter,
        navigate,
        getContent,
        setStepFilter,
        nextCompo,
        previousCompo,
        submitOrganisation,
    } = useCreateOrganisation()
    return (
        <div className="flex flex-col container-global content-full-height overflow-hidden">
            <div className="flex p-3">
                <div className="flex w-full justify-between items-center">
                    <div className="flex">
                        <span className="flex text-size-L font-bold">
                            New Organisation
                        </span>
                    </div>
                    <div className="flex">
                        <ProgressionStep
                            steps={stepCreateOrg}
                            onChange={(step) => setStepFilter(step)}
                            selectedItem={stepFilter}
                        />
                    </div>
                    <div className="flex w-[160px]"></div>
                </div>
            </div>
            <div className="border-b"></div>
            <FormikProvider value={formik}>
                <div className="p-3 h-[calc(100vh-16.5rem)]">
                    {getContent()}
                </div>
            </FormikProvider>

            <div className="border-b"></div>
            <div className="p-3 flex justify-between">
                <Button
                    onClick={() => {
                        navigate(-1)
                    }}
                    label="BACK"
                />
                <div className="flex gap-3">
                    {stepFilter.value !== 'org' && (
                        <Button
                            onClick={() => {
                                previousCompo()
                            }}
                            label="PREVIOUS"
                            className="w-btnRegularWidth "
                            icon="ri-arrow-left-line"
                        />
                    )}
                    {stepFilter.value !== 'contact' && (
                        <Button
                            onClick={() => {
                                nextCompo()
                            }}
                            label="NEXT"
                            iconSuffix="ri-arrow-right-line"
                            className="w-btnRegularWidth !text-size-S"
                        />
                    )}
                    {stepFilter.value === 'contact' && (
                        <Button
                            onClick={() => {
                                submitOrganisation()
                                navigate('/organisation')
                            }}
                            variant="brand"
                            label="CREATE ORG/CLIENT"
                        />
                    )}
                </div>
            </div>
            {allModal}
        </div>
    )
}

export default CreateOrganisation
