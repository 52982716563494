/* eslint-disable no-unused-vars */
import StatusCard from 'components/status-card/status-card.component'
import Button from 'components/button/button.component'
import { useShipmentDetails } from './shipments-details.service'
import { contentShipDetailHeader } from './shipments-details.static'
import Tab from 'components/tab/tab.component'
import Spinner from 'components/spinner/spinner.component'

const ShipmentDetails = () => {
    const {
        getContentShipmentDetails,
        setTabFilter,
        navigate,
        id,
        loadingDetailData,
        tabFilter,
    } = useShipmentDetails()

    const spinnerShow = loadingDetailData ? '' : 'hidden'
    const detailShow = !loadingDetailData ? 'opacity-100' : 'opacity-0'

    return (
        <>
            <div
                className={`${spinnerShow} flex w-full items-center justify-center content-full-height`}
            >
                <Spinner label="Please Wait..." />
            </div>
            <div
                className={`${detailShow} container-global content-full-height sm:overflow-auto overflow-hidden`}
            >
                <div className="flex justify-between items-center px-3 py-4">
                    <div className="flex text-size-L font-bold gap-4 items-center">
                        SHIPMENT DETAILS {id}
                        <StatusCard status={'In Progress'} />
                    </div>
                    <div>
                        {/* <Button
              onClick={() => navigate("/shipments-create")}
              label="EDIT SHIPMENT"
              icon="ri-edit-2-line"
              variant="brand-inverse"
              className="w-btnRegularWidth !text-size-S"
            /> */}
                    </div>
                </div>
                <div className="border-b border-solid border-logistical-gray-ver3"></div>
                <Tab
                    containerClassName="px-3 pt-3 overflow-auto"
                    items={contentShipDetailHeader ?? []}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                />
                <div className="border-b border-solid border-logistical-gray-ver3"></div>
                <div className="p-3 h-[calc(100vh-19rem)] flex">
                    <div className=" w-full">
                        {getContentShipmentDetails(tabFilter)}
                    </div>
                </div>
                <div className="border-b border-solid border-logistical-gray-ver3"></div>
                <div className="flex justify-between items-center p-3 ">
                    <Button
                        label="Back"
                        variant="default"
                        className="w-btnRegularWidth"
                        onClick={() => navigate('/shipments')}
                    />
                </div>
            </div>
        </>
    )
}

export default ShipmentDetails
