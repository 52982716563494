import Button from 'components/button/button.component'
import { useNavigate } from 'react-router-dom'

export interface IUseQuotationForm {
    isNew: boolean
}

const useQuotationForm = () => {
    const navigate = useNavigate()

    //eslint-disable-next-line
    const containerTableActionHandling = (data: any) => {
        return (
            <div className="pr-4 flex items-center gap-x-2">
                <Button
                    onClick={() => { }}
                    variant="red-inverse"
                    icon="ri-delete-bin-line !p-0"
                    className={`!p-0 !h-[1.8rem] !w-[55px]`}
                />
                <Button
                    onClick={() => { }}
                    variant="default"
                    icon="ri-edit-2-line !p-0"
                    className={`!p-0 !h-[1.8rem] !w-[55px]`}
                />
            </div>
        )
    }

    return {
        navigate,
        containerTableActionHandling,
    }
}

export default useQuotationForm
