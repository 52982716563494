import { useContactListForm } from './contact-list-form.service'
import FormTextArea from 'components/form-textarea/form-textarea.component'
import FormInput from 'components/form-input/form-input.component'
import { FormikProvider, useFormik } from 'formik'
import { IContactList, IContactListFormProps } from '../contact-list.interface'
import * as Yup from 'yup'
import { Toast } from 'components/toast/toast.component'
import { ContactListDataDummy } from '../contact-list.dummy'
import Button from 'components/button/button.component'

function ContactListForm({
    modalService,
    isCreateAddress,
    disable,
    moduleTitle,
    data,
}: IContactListFormProps) {
    const contactListInitialValue: IContactList = {
        id: ContactListDataDummy.length + 1,
        fullName: '',
        jobTitle: '',
        jobCategory: '',
        phoneNumber: '',
        email: '',
        active: true,
        organisationList: [],
        lastUpdate: '',
        status: '',
    }

    const { contactListData, setContactListData } = useContactListForm(
        data ? data : contactListInitialValue,
    )

    const contactListValidation = Yup.object({
        contactName: Yup.string().required('Contact Name required'),
    })

    const submitContactList = (data: IContactList) => {
        if (isCreateAddress) {
            setContactListData({ ...contactListData, data })
            Toast({
                header: 'New Contact Created',
                message: `New Contact:${data.fullName} successfully created.`,
                type: 'success',
            })
        } else {
            Toast({
                header: 'Contact Updated',
                message: `Contact:${data.fullName} successfully updated.`,
                type: 'success',
            })
        }
    }

    const statusContactHandling = (isDeleted: boolean) => {
        if (isDeleted) {
            Toast({
                header: 'Contact Updated',
                message: `Contact:${data.fullName} successfully changed to non active.`,
                type: 'success',
            })
        } else {
            Toast({
                header: 'Contact Updated',
                message: `Contact:${data.fullName} successfully changed to active.`,
                type: 'success',
            })
        }
    }

    const contactListFormik = useFormik<IContactList>({
        validationSchema: contactListValidation,
        initialValues:
            moduleTitle === 'Preview Contact Details'
                ? data
                : contactListInitialValue,
        onSubmit: (values) => {
            submitContactList(values)
        },
    })

    return (
        <div className="flex flex-col">
            <div className="flex flex-col gap-3 overflow-auto">
                <FormikProvider value={contactListFormik}>
                    <div className="flex flex-col p-3 gap-3">
                        <FormInput
                            label="Contact name"
                            name="fullName"
                            parentDivClassName="!mb-0 w-full"
                            placeholder="Enter Full Name"
                            disabled={disable}
                            readonly={disable}
                        />
                        <FormInput
                            label="Job Tittle"
                            name="jobTitle"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Job Title"
                            disabled={disable}
                            readonly={disable}
                        />
                        <FormInput
                            label="Job Category"
                            name="jobCategory"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Job Category"
                            disabled={disable}
                            readonly={disable}
                        />
                        <FormInput
                            label="Phone Number"
                            name="phoneNumber"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Phone Number"
                            disabled={disable}
                            readonly={disable}
                        />
                        <FormInput
                            label="Email"
                            name="email"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Email"
                            disabled={disable}
                            readonly={disable}
                        />
                        <FormInput
                            label="Organisation(s)"
                            name="organisationList"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Related Organisation"
                            disabled={disable}
                            readonly={disable}
                        />
                        <FormInput
                            label="Last Update"
                            name="lastUpdate"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Born Date"
                            disabled={disable}
                            readonly={disable}
                        />
                        <FormTextArea
                            label="Working Address (Optional)"
                            name="address"
                            placeholder="Enter Working Address"
                            disabled={disable}
                            readonly={disable}
                        />
                    </div>

                    <>
                        <div className="border-b"></div>
                        <div className="px-3 pb-4 pt-2">
                            <Button
                                onClick={() => {
                                    modalService.closeModalHandling()
                                    contactListFormik.submitForm()
                                    submitContactList(contactListFormik.values)
                                }}
                                label={
                                    moduleTitle === 'Create Contact Details'
                                        ? 'CREATE CONTACT'
                                        : 'UPDATE CONTACT'
                                }
                                variant="brand"
                                className="w-full"
                            />
                            {!isCreateAddress && data.status !== 'nonActive' ? (
                                <Button
                                    onClick={() => {
                                        modalService.closeModalHandling()
                                        contactListFormik.submitForm()
                                        statusContactHandling(true)
                                    }}
                                    label={'CHANGE STATUS TO NON ACTIVE'}
                                    variant="red"
                                    className="w-full mt-1"
                                />
                            ) : (
                                <></>
                            )}

                            {!isCreateAddress && data.status === 'nonActive' ? (
                                <Button
                                    onClick={() => {
                                        modalService.closeModalHandling()
                                        contactListFormik.submitForm()
                                        statusContactHandling(false)
                                    }}
                                    label={'CHANGE STATUS TO ACTIVE'}
                                    variant="brand-inverse"
                                    className="w-full mt-1"
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    </>
                </FormikProvider>
            </div>
        </div>
    )
}

export default ContactListForm
