import { useEffect, useRef, useState } from 'react'
import {
    addressesDetailsConsigneeHeader,
    addressesDetailsShipperHeader,
    descriptionAndIntructionsHeader,
    localAddressesDetailsConsigneeHeader,
    shippingDetailsHeader,
} from './shipments-details.static'
import Details from './content/details.component'
import { ITabItem } from 'components/tab/tab.interface'
import { useNavigate, useParams } from 'react-router-dom'
import { Toast } from 'components/toast/toast.component'
import Containers from './content/containers.component'
import GoodsPacks from './content/goods-packs.component'
import EDocumentation from './content/e-documentation.component'
import RelatedInvoice from './content/related-invoice.component'
import { copyTextToClipboard } from 'common/common.service'
import ListItems from './content/list-items.component'
import Routing from './content/routing.component'
import { useModal } from 'components/modal/modal.service'
import AddEDocumentModal from './components/add-e-document-modal.component'
import { IDropdownItem } from 'components/dropdown/dropdown.interface'

export const shipmentDetailsData: any = {
    shipmentStatus: 'In Transit',
    houseBill: 'HB123456',
    origin: 'Melbourne',
    originSub: 'Australia',
    etd: '10-03-2024',
    destination: 'Bangkok',
    destinationSub: 'Thailand',
    eta: '15-03-2024',
    weight: '500 kg',
    volume: '20 m³',
    chargeable: 'Yes',
    wv: '200 kg',
    packs: 5,
    inners: 25,
    goodsValue: '$10,000',
    insuranceValue: '$2,000',
    description: 'Product A',
    marksAndNumbers: 'ABC123',
    incoterm: 'FOB',
    additionalTerms: 'Standard terms',
    spotRate: '$50 per kg',
    serviceLevel: 'Express',
    entryDetails: 'Customs entry completed',
    shippingDate: {
        issueDate: '01-03-2024',
        expiryDate: '15-03-2024',
    },
    releaseType: 'Normal',
    chargesApply: 'Yes',
    screeningStatus: 'Cleared',
    company: 'LMN Enterprises',
    address: '789 Pine Lane, Villagetown',
    phone: '+1 555-9012',
    email: 'support@lmnenterprises.com',
    fax: '+1 555-3456',
    companyA: 'XYZ Logistics',
    deliveryAddressA: '456 Oak Avenue, Townsville',
    phoneA: '+1 555-5678',
    emailA: 'contact@xyzlogistics.com',
    faxA: '+1 555-9012',
    companyB: 'XYZ Corporation',
    deliveryAddressB: '789 Oak Street, Villagetown',
    phoneB: '+1 555-3456',
    emailB: 'info@xyz-corp.com',
    faxB: '+1 555-7890',

    carrier: 'JKL Shipping Services',
    transport: 'Sea',
    containerType: '45ft High Cube',
    uw: 'tons',
    uv: 'm³',
}

export const useShipmentDetails = () => {
    // initial ----------------------------------------------------
    const { id } = useParams()
    const navigate = useNavigate()

    //  state
    const [loadingDetailData, setLoadingDetailData] = useState(true)
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'Details',
        key: 'status',
        value: 'details',
        className: 'gray',
    })
    const [buttonAction1, setButtonAction1] = useState<any>()
    const [buttonAction2, setButtonAction2] = useState<any>()

    const [pillShippingDetails, setPillShippingDetails] = useState<any>({
        label: 'INFORMATION',
        accessor: 'information',
    })
    const [pillAddressesDetails, setPillAddressesDetails] = useState<any>({
        label: 'SHIPPER',
        accessor: 'shipper',
    })

    // variable
    const organization = 'jpl'
    const addEDocModalService = useModal()

    // upload
    const inputRef = useRef<HTMLInputElement>(null)
    const [eDocsType, setEDocsType] = useState<IDropdownItem>()

    const loadDetailData = async () => {
        if (!id || id === '') {
            return
        }

        try {
            setLoadingDetailData(true)
            // const actionResult = await getShipmentDetails(id);
            // dispatch(setShipmentDetailsData(actionResult));
            setLoadingDetailData(false)
        } catch (e: any) {
            console.log(e)
            setLoadingDetailData(false)
            const errorMessage = e.message
            Toast({
                header: 'Failed Get Detail Shipments',
                message: errorMessage,
                type: 'error',
            })
            setTimeout(() => {
                navigate('/shipments')
            }, 100)
        }
    }

    const getComponentDetail = () => {
        const { shipmentStatus } = shipmentDetailsData

        let button1: any
        let button2: any
        if (organization === 'jpl') {
            switch (shipmentStatus) {
                case 'Booked':
                    if (tabFilter.value === 'details') {
                        button1 = {
                            label: 'ATTACH PO',
                            variant: 'logistical-darkblue',
                            onClick: () => {
                                navigate('/shipments-detail/attach-po/' + id)
                            },
                        }
                    } else {
                        button1 = {
                            label: 'UPDATE PO',
                            variant: 'logistical-lightblue-invert',
                            onClick: () => {
                                navigate('/shipments-detail/attach-po/' + id)
                            },
                        }
                    }
                    break
                case 'Shipped':
                    button1 = {
                        label: 'UPDATE PO',
                        variant: 'logistical-lightblue-invert',
                        onClick: () => {
                            navigate('/shipments-detail/attach-po/' + id)
                        },
                    }
                    button2 = {
                        label: 'SUBMIT DELIVERY',
                        variant: 'logistical-darkblue',
                        onClick: () => {
                            navigate('/shipments-detail/attach-po/' + id)
                        },
                    }
                    break
                case 'Confirmed':
                    button1 = undefined
                    break
                default:
                    button1 = {
                        label: 'UPDATE PO',
                        variant: 'logistical-lightblue-invert',
                        onClick: () => {
                            navigate('/shipments-detail/attach-po/' + id)
                        },
                    }
                    break
            }
        } else {
            switch (shipmentStatus) {
                case 'Delivered':
                    button1 = {
                        label: 'ORDER RECEIVED',
                        variant: 'logistical-darkblue',
                        onClick: () => {},
                    }
                    break
                case 'In Factory':
                    button1 = {
                        label: 'CONFIRM SHIPMENT',
                        variant: 'logistical-darkblue',
                        onClick: () => {},
                    }
                    break
            }
            if (tabFilter.value === 'listPurchaseOrders')
                button1 = {
                    label: 'UPDATE PO',
                    variant: 'logistical-lightblue-invert',
                    onClick: () => {},
                }
        }

        setButtonAction1(button1)
        if (button2) setButtonAction2(button2)
    }

    // handle browse/choose file for upload
    const onChooseFile = () => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }

    // handle drag upload file
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }

    // handle drop upload file
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0]
            if (validateFile(file)) {
                setSelectedFile(file)
            }
        }
    }
    // validate file when upload file
    const validateFile = (file: File): boolean => {
        // Validasi jenis file
        let messageError
        const allowedExtensions = [
            '.png',
            '.jpg',
            '.jpeg',
            '.pdf',
            '.xls',
            '.xlsx',
        ]
        const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase()

        if (!allowedExtensions.includes(`.${fileExtension}`)) {
            messageError = 'Only PNG, JPG, PDF, XLS and XLSX files are allowed.'
        }

        // Validasi ukuran file (dalam bytes)
        const maxSize = 3 * 1024 * 1024 // 3MB
        if (file.size > maxSize) {
            messageError = 'File size exceeds 3MB limit.'
        }

        if (messageError) {
            Toast({
                header: 'Error Selected File',
                message: messageError,
                type: 'error',
            })
            return false
        }

        return true
    }

    // all fucntion for get content
    // get content shipmentDetals
    const getContentShipmentDetails = (tabFilter: any) => {
        const addEDocModalComponent = (
            <AddEDocumentModal
                modalService={addEDocModalService}
                handleFileChange={() => {}}
                onChooseFile={onChooseFile}
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
                selectedFile={selectedFile}
                inputRef={inputRef}
                eDocsType={eDocsType}
                setEDocsType={setEDocsType}
                onSubmit={() => {}}
            />
        )

        const items: any = {
            details: Details({
                setPillShippingDetails,
                setPillAddressesDetails,
                getContentPillShippingDetails:
                    getContentPillShippingDetails(pillShippingDetails),
                getContentPillAddressesDetails:
                    getContentPillAddressesDetails(pillAddressesDetails),
            }),
            listItems: ListItems(),
            // milestones: Milestones(),
            routing: Routing(shipmentDetailsData?.transports),
            goodsPacks: GoodsPacks(),
            containers: Containers(shipmentDetailsData?.containers),
            eDocumentation: EDocumentation({
                modalComponent: addEDocModalComponent,
                modalService: addEDocModalService,
            }),
            relatedInvoice: RelatedInvoice(),
        }
        return items[tabFilter?.value] || <></>
    }

    const getContentPillShippingDetails = (pill: any) => {
        const contents: any = {
            information: (
                <div>
                    <div className="py-2 text-[#424A58] font-bold">
                        Shipping Details
                    </div>
                    <div className="grid grid-cols-2 gap-y-2">
                        {shippingDetailsHeader?.shippingDetails?.map(
                            (header: any) => (
                                <div
                                    className={`${header.accessor === 'status' && 'col-span-2'}`}
                                >
                                    <div className="text-size-M text-[#707785] font-bold">
                                        {header.label}
                                    </div>
                                    <div className={`text-[#424A58]`}>
                                        {header.customBuild
                                            ? header.customBuild(
                                                  shipmentDetailsData[
                                                      header?.accessor
                                                  ] || '-',
                                              )
                                            : shipmentDetailsData[
                                                    header?.accessor
                                                ] === 0
                                              ? 0
                                              : shipmentDetailsData[
                                                    header?.accessor
                                                ] || '-'}
                                    </div>
                                </div>
                            ),
                        )}
                    </div>
                    <div className="py-2 text-[#424A58] font-bold">Mode</div>
                    <div className="grid grid-cols-2 gap-y-2">
                        {shippingDetailsHeader?.mode?.map((header: any) => (
                            <div
                                className={`${header.accessor === 'status' && 'col-span-2'}`}
                            >
                                <div className="text-size-M text-[#707785] font-bold">
                                    {header.label}
                                </div>
                                <div className={`text-[#424A58]`}>
                                    {header.customBuild
                                        ? header.customBuild(
                                              shipmentDetailsData[
                                                  header?.accessor
                                              ] || '-',
                                          )
                                        : shipmentDetailsData[
                                                header?.accessor
                                            ] === 0
                                          ? 0
                                          : shipmentDetailsData[
                                                header?.accessor
                                            ] || '-'}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ),
            descriptionsAndIntructions: (
                <div className="grid grid-cols-2 gap-2">
                    {descriptionAndIntructionsHeader.map((header: any) => {
                        const classNameColSpan =
                            header?.accessor === 'shippingDate'
                                ? 'col-span-1'
                                : 'col-span-2'
                        return (
                            <div className={`${classNameColSpan}`}>
                                <div
                                    className={`text-size-M text-[#707785] font-bold`}
                                >
                                    {header?.label}
                                </div>
                                <div className={`text-[#424A58] col-span-2`}>
                                    {header.customBuild
                                        ? header.customBuild(
                                              shipmentDetailsData[
                                                  header?.accessor
                                              ],
                                          )
                                        : shipmentDetailsData[
                                                header?.accessor
                                            ] === 0
                                          ? 0
                                          : shipmentDetailsData[
                                                header?.accessor
                                            ] || '-'}
                                </div>
                            </div>
                        )
                    })}
                </div>
            ),
        }
        return <div>{contents[pill?.accessor]}</div>
    }

    const getContentPillAddressesDetails = (pill: any) => {
        const contents: any = {
            shipper: addressesDetailsShipperHeader?.map((header: any) => (
                <div>
                    <div className="text-size-M text-[#707785] font-bold">
                        {header?.label}
                    </div>
                    <div className="text-[#424A58]">
                        {shipmentDetailsData[header?.accessor] ? (
                            <div id={header?.accessor}>
                                {shipmentDetailsData[header?.accessor]}{' '}
                                <i
                                    className="ri-file-copy-line cursor-pointer"
                                    onClick={() =>
                                        copyTextToClipboard(header?.accessor)
                                    }
                                ></i>
                            </div>
                        ) : (
                            '-'
                        )}
                    </div>
                </div>
            )),
            consignee: addressesDetailsConsigneeHeader?.map((header: any) => (
                <div>
                    <div className="text-size-M text-[#707785] font-bold">
                        {header?.label}
                    </div>
                    <div className="text-[#424A58]">
                        {shipmentDetailsData[header?.accessor] ? (
                            <div id={header?.accessor}>
                                {shipmentDetailsData[header?.accessor]}{' '}
                                <i
                                    className="ri-file-copy-line cursor-pointer"
                                    onClick={() =>
                                        copyTextToClipboard(header?.accessor)
                                    }
                                ></i>
                            </div>
                        ) : (
                            '-'
                        )}
                    </div>
                </div>
            )),
            localClient: localAddressesDetailsConsigneeHeader?.map(
                (header: any) => (
                    <div>
                        <div className="text-size-M text-[#707785] font-bold">
                            {header?.label}
                        </div>
                        <div className="text-[#424A58]">
                            {shipmentDetailsData[header?.accessor] ? (
                                <div id={header?.accessor}>
                                    {shipmentDetailsData[header?.accessor]}{' '}
                                    <i
                                        className="ri-file-copy-line cursor-pointer"
                                        onClick={() =>
                                            copyTextToClipboard(
                                                header?.accessor,
                                            )
                                        }
                                    ></i>
                                </div>
                            ) : (
                                '-'
                            )}
                        </div>
                    </div>
                ),
            ),
        }

        return contents[pill?.accessor]
    }

    // Use Effect ----------------------------------------------
    useEffect(() => {
        if (!id) return
        loadDetailData()
    }, [id])

    useEffect(() => {
        if (!shipmentDetailsData.shipmentID) return
        getComponentDetail()
    }, [shipmentDetailsData, tabFilter])

    return {
        getContentShipmentDetails,
        setTabFilter,
        navigate,
        loadingDetailData,
        buttonAction1,
        buttonAction2,
        tabFilter,
        id,
    }
}
