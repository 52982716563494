/* eslint-disable no-unused-vars */
import Button from 'components/button/button.component'
import { ISteps } from 'components/progression-step/progression-step.interface'

export const items: ISteps[] = [
    {
        textIcon: '01',
        label: 'Address',
        value: 'address',
    },
    {
        textIcon: '02',
        label: 'Goods Details',
        value: 'goodsDetails',
    },
    {
        textIcon: '03',
        label: 'Documents',
        value: 'documents',
    },
]

export const PackLinesHeader = [
    {
        accessor: 'packs',
        label: 'Packs',
    },
    {
        accessor: 'packageType',
        label: 'Package Type',
    },
    {
        accessor: 'lengthMeasure',
        label: 'Length Measure',
    },
    {
        accessor: 'heightMeasure',
        label: 'Height Measure',
    },
    {
        accessor: 'volumeMeasure',
        label: 'Volume Measure',
    },
    {
        accessor: 'goodsDescription',
        label: 'Goods Description',
    },
    {
        accessor: 'id',
        label: 'Action',
        customBuild: () => (
            <div className="pr-4 flex items-center gap-x-2">
                <Button
                    onClick={() => {}}
                    variant="red-inverse"
                    icon="ri-delete-bin-line !p-0"
                    className={`!p-0  !h-[1.8rem] !w-[55px]`}
                />
                <Button
                    onClick={() => {}}
                    variant="default"
                    icon="ri-edit-2-line !p-0"
                    className={`!p-0 !h-[1.8rem] !w-[55px]`}
                />
            </div>
        ),
    },
]

export const DocumentsHeader = [
    {
        accessor: 'actions',
        label: 'Actions',
    },
    {
        accessor: 'fileName',
        label: 'File Name',
    },
    {
        accessor: 'description',
        label: 'Description',
    },
    {
        accessor: 'dateAdded',
        label: 'Date Added',
    },
    {
        accessor: 'id',
        label: 'Action',
        customBuild: () => (
            <div className="pr-4 flex items-center gap-x-2">
                <Button
                    onClick={() => {}}
                    variant="red-inverse"
                    icon="ri-delete-bin-line !p-0"
                    className={`!p-0  !h-[1.8rem] !w-[55px]`}
                />
                <Button
                    onClick={() => {}}
                    variant="default"
                    icon="ri-edit-2-line !p-0"
                    className={`!p-0 !h-[1.8rem] !w-[55px]`}
                />
            </div>
        ),
    },
]
