
import Dropdown from "components/dropdown/dropdown.component";
import Input from "components/input/input.component";
import Textarea from "components/text-area/text-area.component";
import TransportationSelection from "components/transportation-mode-button/transportation-selection.component";
import { TransportationSelectionItems } from "../create-booking.static";

const TransportationAndGoods = () => {
  return (
    <div className="flex flex-col w-full p-4 gap-4 h-[calc(100vh-280px)] overflow-y-auto">
      <div className="flex">
        <span className=" text-size-L">Transportation Information</span>
      </div>
      <div className="flex w-full gap-x-4 ">
        <TransportationSelection items={TransportationSelectionItems} />
      </div>
      <div className="flex w-full gap-x-4">
        <div className="flex w-1/2">
          <Dropdown
            label={"FLIGHT OR VESSEL NUMBER"}
            options={[
              {
                label: "12345SAB",
                value: "12345SAB",
              },
            ]}
          />
        </div>
        <div className="flex w-1/2 gap-x-4">
          <div className="flex w-1/4">
            <Dropdown
              label={"INCOTERM"}
              options={[
                {
                  label: "FOB",
                  value: "FOB",
                  selected: true
                },
              ]}
            />
          </div>
          <div className="flex w-3/4">
            <Input
              placeholder={"FREE ON BOARD"}
              type={"text"}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-x-4">
        <div className="flex w-1/2 gap-x-4">
          <div className="flex w-1/2">
            <Dropdown
              label={"CARRIER NAME"}
              options={[
                {
                  label: "ABC Logistics",
                  value: "ABC Logistics",
                },
              ]}
            />
          </div>
          <div className="flex w-1/2">
            <Dropdown
              label={"Mode Type"}
              options={[
                {
                  label: "Express Service",
                  value: "Express Service",
                },
              ]}
            />
          </div>
        </div>
        <div className="flex w-1/2">
          <Dropdown
            label={"Container Type"}
            options={[
              {
                label: "Standard 20FT Container",
                value: "Standard 20FT Container",
              },
            ]}
          />
        </div>
      </div>
      <div className="border-b border-logistical-gray-ver7" />
      <div className="flex">
        <span className=" text-size-L">Goods Details</span>
      </div>
      <div className="flex w-full gap-x-4">
        <div className="flex w-1/2">
          <Textarea
            label={"ADDRESS"}
            placeholder={"Input address..."}
            className="!h-[152px]"
          />
        </div>
        <div className="flex flex-col w-1/2 gap-y-4">
          <div className="flex gap-x-4">
            <div className="flex w-2/3">
              <Input
                label="WEIGHT"
                placeholder={"FREE ON BOARD"}
                type={"text"}
              />
            </div>
            <div className="flex w-1/3">
              <Dropdown
                label={"WEIGHT UNIT"}
                options={[
                  {
                    value: "KG",
                    label: "KG",
                  },
                ]}
              />
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="flex w-2/3">
              <Input
                label="VOLUME"
                placeholder={"FREE ON BOARD"}
                type={"text"}
              />
            </div>
            <div className="flex w-1/3">
              <Dropdown
                label={"Volume"}
                options={[
                  {
                    label: "Cubic Meters",
                    value: "Cubic Meters",
                  },
                ]}
              />
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="flex w-2/3">
              <Input
                label="PACKS"
                placeholder={"FREE ON BOARD"}
                type={"text"}
              />
            </div>
            <div className="flex w-1/3">
              <Dropdown
                label={"Packs Type"}
                options={[
                  {
                    label: "Cartons",
                    value: "Cartons",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TransportationAndGoods;
