import { useState } from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import {
    zoomPlugin,
    RenderZoomInProps,
    RenderZoomOutProps,
    RenderCurrentScaleProps,
} from '@react-pdf-viewer/zoom'
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'
import '@react-pdf-viewer/zoom/lib/styles/index.css'
import { useModal } from 'components/modal/modal.service'
import Modal from 'components/modal/modal.component'
import Button from 'components/button/button.component'
import { IButtonPreviewDocument } from './button-preview-document.interface'

const pdfjsVersion = '3.11.174' // Replace this with the version of pdfjs-dist you are using

const ButtonPreviewDocument = ({
    componentPdf,
    onClick,
    isPreview,
    ...props
}: IButtonPreviewDocument) => {
    const [pdfData, setPdfData] = useState<Uint8Array | null>(null)

    const toolbarPluginInstance = toolbarPlugin()
    const zoomPluginInstance = zoomPlugin()
    const getFilePluginInstance = getFilePlugin()

    const { ZoomIn, ZoomOut, CurrentScale } = zoomPluginInstance

    const { Download } = getFilePluginInstance
    const modalService = useModal()
    const generateAndPreviewPDF = async () => {
        console.log('componentPdf', componentPdf)

        try {
            const input = document.getElementById('pdf-content')
            if (input) {
                // input.classList.add('!block')
                const scale = 2 // Increase scale for better quality
                const canvas = await html2canvas(input, {
                    scale,
                    useCORS: true,
                    allowTaint: true,
                    width: input.scrollWidth,
                    height: input.scrollHeight,
                })

                if (!canvas) {
                    console.error('Canvas not created')
                    return
                }

                const imgData = canvas.toDataURL('image/png')
                if (!imgData) {
                    console.error('Image data is empty')
                    return
                }

                console.log('Image Data URL:', imgData) // Log the image data URL to check if it's valid

                // Fallback static image data URL for testing
                // const imgData = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAU...'; // Use a valid base64 image data URL for testing

                const pdf = new jsPDF('p', 'mm', 'a4')
                const pdfWidth = pdf.internal.pageSize.getWidth()
                const imgProps = pdf.getImageProperties(imgData)
                const imgWidth = pdfWidth
                const imgHeight = (imgProps.height * pdfWidth) / imgProps.width

                console.log('Image Properties:', imgProps) // Log image properties

                pdf.addImage(
                    imgData,
                    'PNG',
                    0,
                    0,
                    imgWidth,
                    imgHeight,
                    'someAlias',
                    'FAST',
                )
                const pdfBlob = pdf.output('blob')
                const pdfArrayBuffer = await pdfBlob.arrayBuffer()
                if (isPreview) {
                    setPdfData(new Uint8Array(pdfArrayBuffer))
                    modalService.openModalHandling()
                } else {
                    pdf.save('quote-001.pdf')
                }
            }
        } catch (error) {
            console.error('Error generating PDF:', error) // Catch and log any errors
        }
    }

    const hiddenComponent = useModal()

    const pdfDataUrl = pdfData
        ? URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }))
        : ''

    return (
        <div className="preview-document">
            <Button
                onClick={() => {
                    generateAndPreviewPDF()
                    onClick()
                }}
                {...props}
            />
            {/* modal for hidden component */}
            <Modal
                isModalOpen={hiddenComponent.isModalOpen}
                className="h-full !p-0"
            >
                <div id="pdf-content" className="p-3 themes-bg-white">
                    {componentPdf}
                </div>
            </Modal>
            {pdfData && (
                <Modal
                    isModalOpen={modalService.isModalOpen}
                    className="h-full !p-0"
                >
                    <div
                        style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                overflow: 'hidden',
                            }}
                        >
                            <Worker
                                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
                            >
                                <Viewer
                                    fileUrl={pdfDataUrl}
                                    plugins={[
                                        toolbarPluginInstance,
                                        zoomPluginInstance,
                                        getFilePluginInstance,
                                    ]}
                                />
                            </Worker>
                        </div>
                        <div
                            className="themes-bg-white"
                            style={{
                                alignItems: 'center',
                                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '8px',
                            }}
                        >
                            <ZoomOut>
                                {(props: RenderZoomOutProps) => (
                                    <Button
                                        label="Zoom out"
                                        variant="brand"
                                        onClick={props.onClick}
                                    />
                                )}
                            </ZoomOut>
                            <CurrentScale>
                                {(props: RenderCurrentScaleProps) => (
                                    <span
                                        style={{ margin: '0 8px' }}
                                    >{`${Math.round(props.scale * 100)}%`}</span>
                                )}
                            </CurrentScale>
                            <ZoomIn>
                                {(props: RenderZoomInProps) => (
                                    <Button
                                        label="Zoom in"
                                        variant="brand"
                                        onClick={props.onClick}
                                    />
                                )}
                            </ZoomIn>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                top: '8px',
                                right: '8px',
                            }}
                        >
                            <Download>
                                {(props: RenderDownloadProps) => (
                                    <Button
                                        label="Download"
                                        variant="brand"
                                        onClick={props.onClick}
                                    />
                                )}
                            </Download>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                top: '8px',
                                left: '8px',
                            }}
                        >
                            <Button
                                label="Back"
                                variant="brand"
                                onClick={() => setPdfData(null)}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default ButtonPreviewDocument
