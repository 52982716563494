import { ITabItem } from 'components/tab/tab.interface'
import { ContactListDataDummy } from './contact-list.dummy'
import { ISTColumn } from 'components/simple-table/simple-table.interface'
import { IContactList } from './contact-list.interface'

export const ContactListStatusHeaders: ITabItem[] = [
    {
        label: 'Active',
        totalData: ContactListDataDummy.filter(
            (x: any) => x.status === 'active',
        ).length,
        key: 'status',
        value: 'active',
        className: 'brand',
    },
    {
        label: 'Non Active',
        totalData: ContactListDataDummy.filter(
            (x: any) => x.status === 'nonActive',
        ).length,
        key: 'status',
        value: 'nonActive',
        className: 'red',
    },
]

export const ContactListSummaryHeaders: ISTColumn<IContactList>[] = [
    {
        label: 'Full Name',
        accessor: 'fullName',
    },
    {
        label: 'Job Title',
        accessor: 'jobTitle',
    },
    {
        label: 'Job Category',
        accessor: 'jobCategory',
    },
    {
        label: 'Email',
        accessor: 'email',
    },
    {
        label: 'Phone Number',
        accessor: 'phoneNumber',
    },
    {
        label: 'Organisation(s)',
        accessor: 'organisationList',
        customBuild: () => {
            // console.log(data)
            return (
                <div className="flex gap-x-2">
                    <div className="themes-bg-gray-v2 px-2 py-[1px] rounded">
                        SHIPPER
                    </div>
                    <div className="themes-bg-gray-v2 px-2 py-[1px] rounded">
                        RECEIVABLES
                    </div>
                    <div className="themes-bg-gray-v2 px-2 py-[1px] rounded">
                        +3
                    </div>
                </div>
            )
        },
    },
    {
        label: 'Last Updated',
        accessor: 'lastUpdate',
    },
    {
        label: '',
        accessor: 'actions',
        customBuild: () => {
            return (
                <i
                    className="ri-more-2-fill flex justify-center cursor-pointer"
                    onClick={() => {}}
                />
            )
        },
    },
]
