import { IButton } from './button.interface'
import './button.style.css'

const Button = <T,>({
    type = 'button',
    isDisabled = false,
    onClick,
    isLoading = false,
    useUpperCase,
    label = '',
    variant = 'default',
    iconSuffix,
    iconClassName,
    ...props
}: IButton<T>) => {
    const disableButton = isLoading ? true : isDisabled
    const newLabel = useUpperCase ? label?.toUpperCase() : label
    const buttonVariant = 'button-' + variant
    const hasIcon = props.icon || iconSuffix
    const buttonIconClass = hasIcon && !label ? 'button-icon' : ''
    const customLabel = isLoading ? (
        'PLEASE WAIT'
    ) : (
        <>
            {!props.icon ? undefined : (
                <>
                    <i
                        className={`${props.icon} ${iconClassName} ${newLabel ? 'pr-2' : ''}`}
                    />
                </>
            )}
            {newLabel}
            {!iconSuffix ? undefined : (
                <>
                    <i
                        className={`${iconSuffix} ${iconClassName}  ${newLabel ? 'pl-2' : ''}`}
                    />
                </>
            )}
        </>
    )

    return (
        <button
            {...props}
            type={type}
            disabled={disableButton}
            onClick={
                isDisabled
                    ? undefined
                    : () => {
                          onClick()
                      }
            }
            className={`loglines-button ${buttonVariant} ${props.className} ${buttonIconClass}`}
        >
            {customLabel}
        </button>
    )
}
export default Button
