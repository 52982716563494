import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserData } from 'repository/common/user.repository'
import { useAppDispatch } from 'store'
import {
    filterSelector,
    setFilterSearchUser,
    setPageNumber,
    setSelectedStatus,
    setUserData,
    tabItemsSelector,
    tabStatusFilterSelector,
    userDataSelector,
    userMeta,
} from './user-access.slice'
import { useSelector } from 'react-redux'
import { ITabItem } from 'components/tab/tab.interface'
import { Toast } from 'components/toast/toast.component'
import useOverlay from 'components/overlay/overlay.service'

const useUserAccess = () => {
    // initial
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const userData = useSelector(userDataSelector)
    const userDataMeta = useSelector(userMeta)
    const filter = useSelector(filterSelector)
    const tabFilter = useSelector(tabStatusFilterSelector)
    const tabItems = useSelector(tabItemsSelector)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const { pageNumber, pageSize, search, status } = filter

    // useEffect when filter applied
    useEffect(() => {
        loadData()
    }, [pageNumber, pageSize, search, status, tabFilter])

    // set data filter from tab
    const setTabFilter = (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    // set page
    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    // overlay
    const filterOverlayService = useOverlay()

    // Get Data Function
    const loadData = async () => {
        // search
        try {
            setLoading(true)
            try {
                const filterByStatus = tabFilter.value ?? 'Active'
                const response = getUserData(filterByStatus)
                dispatch(setUserData(response))
            } catch (e) {
                Toast({
                    header: 'Failed!',
                    message: 'Failed to get user data',
                    type: 'error',
                })
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    const setSearch = (values: string) => {
        dispatch(setFilterSearchUser(values))
    }

    return {
        navigate,
        setLoading,
        setErrorMessage,
        setTabFilter,
        setPageData,
        loadData,
        setSearch,
        loading,
        errorMessage,
        userData,
        tabFilter,
        userDataMeta,
        tabItems,
        filterOverlayService,
    }
}

export default useUserAccess
