import { ISteps } from 'components/progression-step/progression-step.interface'
import { useState } from 'react'
import { items } from './setup-site-create.static'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { Toast } from 'components/toast/toast.component'
import { IResponseData } from 'common/common.interface'
import BasicInformation from './contents/basic-information.component'
import DangerousGoodsDetails from './contents/dangerous-goods-details.component'
import { ISite, createSiteInitial } from 'form-validation/poc/site.validation'
import { createSite } from 'repository/poc/site.repository'

const useSetupSiteCreate = () => {
    const navigate = useNavigate()

    const [isSubmit, setIsSubmit] = useState<boolean>(false)
    const [stepFilter, setStepFilter] = useState<ISteps>({
        icon: 'ri-file-list-3-fill',
        label: 'Basic Information',
        value: 'basicInformation',
    })

    const nextCompo = () => {
        const x = items.findIndex((item) => item.value === stepFilter.value)
        setStepFilter(items[x + 1])
        console.log('X: ', x)
    }

    const previousCompo = () => {
        const x = items.findIndex((item) => item.value === stepFilter.value)
        setStepFilter(items[x - 1])
        console.log('X: ', x)
    }

    const getContentCreateBooking = (step: ISteps) => {
        const items: any = {
            basicInformation: BasicInformation(),
            dangerousGoodsDetails: DangerousGoodsDetails(),
        }

        return <>{items[step?.value]}</>
    }

    const formikModule = useFormik<ISite>({
        initialValues: createSiteInitial,
        onSubmit: (values) => {
            submit({ data: values })
        },
    })

    const submit = async ({ data }: { data: ISite }) => {
        setIsSubmit(true)

        let payload: ISite = { ...data }

        let submitApi: IResponseData<any>

        submitApi = await createSite(payload)

        if (submitApi.isSuccess) {
            Toast({
                header: 'Success!',
                message: 'success create user',
                type: 'success',
            })

            setTimeout(() => {
                navigate('/user')
            }, 100)
            setIsSubmit(false)
        } else {
            Toast({
                header: 'Failed!',
                message: submitApi?.message ?? 'failed to update user',
                type: 'error',
            })
            setIsSubmit(false)
        }
    }

    return {
        stepFilter,
        isSubmit,
        formikModule,
        navigate,
        setStepFilter,
        getContentCreateBooking,
        nextCompo,
        previousCompo,
    }
}

export default useSetupSiteCreate
