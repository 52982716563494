/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { listItemsHeader } from "../shipments-details.static";
import SimpleTable from "components/simple-table/simple-table.component";

const ListItems = () => {
  const data = [
    {
      itemNo: "ITM 001",
      itemDescription: "18960 #1 1TG REV SLIDER COATED",
      category: "FABRIC",
      unit: "120",
      price: "$900",
      amount: "$98,000",
      material: "COTTON",
      hsCode: "324",
      size: "5 KG",
      color: "WHITE",
      handlingNote: "",
    },
    {
      itemNo: "ITM 002",
      itemDescription: "18960 #2 1TG REV SLIDER COATED",
      category: "FABRIC",
      unit: "120",
      price: "$900",
      amount: "$98,000",
      material: "COTTON",
      hsCode: "324",
      size: "5 KG",
      color: "WHITE",
      handlingNote: "",
    },
    {
      itemNo: "ITM 003",
      itemDescription: "18960 #3 1TG REV SLIDER COATED",
      category: "FABRIC",
      unit: "120",
      price: "$900",
      amount: "$98,000",
      material: "COTTON",
      hsCode: "324",
      size: "5 KG",
      color: "WHITE",
      handlingNote: "",
    },
  ];

  return (
    <SimpleTable<any> headers={listItemsHeader} data={data} />
  );
};

export default ListItems;
