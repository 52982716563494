import React from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell } from 'recharts'
import { formatXAxis } from '../common'

function BarChartCustom({ data, tooltipTitle }: any) {

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="flex flex-row gap-2 bg-gray-700 rounded-lg text-white text-xs font-normal px-4 py-2 font-['Basis Grotesque Arabic Pro']">
                    {/* <p className="label">{`${label} : ${payload[0].value}`}</p> */}
                    <p className="w-2 h-2 bg-blue-100 rounded-full self-center"></p>
                    <p className="label">{`${tooltipTitle} : ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };
    const barColors = ["#D9DDE1", "#D9DDE1", "#D9DDE1", "#D9DDE1", "#D9DDE1", "#D9DDE1", "#D8E7FF"]

    return (
        <ResponsiveContainer
            width="100%"
            height="100%"
            className="row-span-8"
        >
            <BarChart width={150} height={40} data={data} margin={{
                top: 5,
                right: 5,
                left: -25,
                bottom: -10,
            }}>
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name" />
                <YAxis
                    tickFormatter={(tick) => formatXAxis(tick)}
                    type={'number'}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="number" fill="#D9DDE1" >
                    {
                        data.map((entry: any, index: any) => (
                            <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>

    )
}

export default BarChartCustom