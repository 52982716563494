import { ISTColumn } from 'components/simple-table/simple-table.interface'
import { ITabItem } from 'components/tab/tab.interface'
import {
    IAccounts,
    IAddressList,
    IContactList,
    IEDocumentations,
} from './organisation-details.interface'
import Checkbox from 'components/checkbox/checkbox.component'
import ActionSimpleTableOverlay from './components/action-simple-table.overlay.component'
import { ISteps } from 'components/progression-step/progression-step.interface'

export const organisationDetailTabHeaders: ITabItem[] = [
    {
        label: 'Details',
        key: 'status',
        value: 'details',
    },
    {
        label: 'Address List',
        key: 'status',
        value: 'addressList',
    },
    {
        label: 'Contact List',
        key: 'status',
        value: 'contactList',
    },
    {
        label: 'Accounts',
        key: 'status',
        value: 'accounts',
    },
    {
        label: 'eDocumentations',
        key: 'status',
        value: 'eDocumentations',
    },
]

export const contactTypeHeaders = [
    {
        label: 'Receivables',
        selected: 'receivables',
    },
    {
        label: 'Payables',
        selected: 'payables',
    },
    {
        label: 'Shipper',
        selected: 'Shipper',
    },
    {
        label: 'Consignee',
        selected: 'consignee',
    },
    {
        label: 'Transport Client',
        selected: 'transportClient',
    },
    {
        label: 'Warehouse',
        selected: 'warehouse',
    },
    {
        label: 'Customer',
        selected: 'customer',
    },
    {
        label: 'Carrier',
        selected: 'carrier',
    },
    {
        label: 'Supplier',
        selected: 'Supplier',
    },
    {
        label: 'Forwarder/Agent',
        selected: 'forwarderOrAgent',
    },
    {
        label: 'Devlivery Agent',
        selected: 'devliveryAgent',
    },
    {
        label: 'VIP',
        selected: 'vip',
    },
    {
        label: 'Broker',
        selected: 'broker',
    },
    {
        label: 'Services',
        selected: 'services',
    },
    {
        label: 'Competitor',
        selected: 'competitor',
    },
    {
        label: 'Sales',
        selected: 'sales',
    },
    {
        label: 'Store/Shop',
        selected: 'storeOrShop',
    },
]

export const addressListHeaders: ISTColumn<IAddressList>[] = [
    {
        label: 'Address Name',
        accessor: 'addressName',
    },
    {
        label: 'Unloco',
        accessor: 'unloco',
    },
    {
        label: 'Country/Region',
        accessor: 'countryOrRegion',
    },
    {
        label: 'State',
        accessor: 'state',
    },
    {
        label: 'City',
        accessor: 'city',
    },
    {
        label: 'Postcode',
        accessor: 'postcode',
    },
    {
        label: 'Main Address',
        accessor: 'mainAddress',
        customBuild: (data, x, y, customBuildParam4, rowData) => {
            if (!customBuildParam4 || !rowData) {
                console.error('customBuildParam4 or rowData is undefined')
                return <></>
            }
            const customFunctions = customBuildParam4(rowData)
            return (
                <Checkbox
                    click={() => {
                        customFunctions?.func1 && customFunctions?.func1()
                    }}
                    onChecked={() => {}}
                    isChecked={data as unknown as boolean}
                />
            )
        },
    },
    {
        label: 'Active Address',
        accessor: 'activeAddress',
        customBuild: (data, x, y, z, rowData) => {
            return (
                <Checkbox
                    onChecked={() => {}}
                    isChecked={rowData?.activeAddress}
                    disabled={true}
                />
            )
        },
    },
    {
        label: '',
        accessor: 'id',
        customBuild: (data, x, y, customBuildParam4, rowData) => {
            if (!customBuildParam4 || !rowData) {
                console.error('customBuildParam4 or rowData is undefined')
                return <></>
            }
            const customFunctions = customBuildParam4(rowData)
            return (
                <ActionSimpleTableOverlay
                    content={'addressList'}
                    functions={{
                        func1: customFunctions.func1,
                        func2: customFunctions.func2,
                    }}
                />
            )
        },
    },
]

export const contactListHeaders: ISTColumn<IContactList>[] = [
    { label: 'Contact Name', accessor: 'contactName' },
    { label: 'Job Title', accessor: 'jobTitle' },
    { label: 'Job Category', accessor: 'jobCategory' },
    { label: 'Phone Number', accessor: 'phoneNumber' },
    { label: 'Email', accessor: 'email' },
    {
        label: 'Active',
        accessor: 'active',
        customBuild: (data, x, y, z, rowData) => {
            return (
                <Checkbox
                    onChecked={() => {}}
                    isChecked={rowData?.active}
                    disabled
                />
            )
        },
    },
    {
        label: '',
        accessor: 'contactName',
        customBuild: (data, x, y, customBuildParam4, rowData) => {
            if (!customBuildParam4 || !rowData) {
                console.error('customBuildParam4 or rowData is undefined')
                return <></>
            }
            const customFunctions = customBuildParam4(rowData)
            return (
                <ActionSimpleTableOverlay
                    content={'contactList'}
                    functions={{
                        func1: customFunctions.func1,
                        func2: customFunctions.func2,
                    }}
                />
            )
        },
    },
]

export const accountHeaders: ISTColumn<IAccounts>[] = [
    {
        label: 'Account Name',
        accessor: 'accountName',
    },
    {
        label: 'Address List',
        accessor: 'addressList',
    },
    {
        label: 'Routing Number',
        accessor: 'routingNumber',
    },
    {
        label: 'Currency',
        accessor: 'currency',
    },
    {
        label: 'Default Acc.',
        accessor: 'defaultAcc',
        customBuild: (data, x, y, z, rowData) => {
            return (
                <Checkbox
                    onChecked={() => {}}
                    isChecked={rowData?.defaultAcc}
                    disabled
                />
            )
        },
    },
    {
        label: '',
        accessor: 'accountName',
        customBuild: (data, x, y, customBuildParam4, rowData) => {
            if (!customBuildParam4 || !rowData) {
                console.error('customBuildParam4 or rowData is undefined')
                return <></>
            }
            const customFunctions = customBuildParam4(rowData)
            return (
                <ActionSimpleTableOverlay
                    content={'accounts'}
                    functions={{
                        func1: customFunctions.func1,
                        func2: customFunctions.func2,
                    }}
                />
            )
        },
    },
]

export const eDocumentationHeaders: ISTColumn<IEDocumentations>[] = [
    {
        label: 'Date Added',
        accessor: 'dateAdded',
    },
    {
        label: 'File Name',
        accessor: 'fileName',
    },
    {
        label: 'Document Type',
        accessor: 'documentType',
    },
    {
        label: 'Desc.',
        accessor: 'desc',
    },
    {
        label: 'Prog. Descriptions',
        accessor: 'progDescriptions',
    },
    {
        label: '',
        accessor: 'dateAdded',
        customBuild: (data, x, y, customBuildParam4, rowData) => {
            if (!customBuildParam4 || !rowData) {
                console.error('customBuildParam4 or rowData is undefined')
                return <></>
            }
            const customFunctions = customBuildParam4(rowData)
            return (
                <ActionSimpleTableOverlay
                    content={'eDocumentations'}
                    functions={{
                        func1: customFunctions.func1,
                        func2: customFunctions.func2,
                    }}
                />
            )
        },
    },
]

// segment create org
export const stepCreateOrg: ISteps[] = [
    {
        textIcon: '01',
        label: 'Details',
        value: 'org',
    },
    {
        textIcon: '02',
        label: 'Address',
        value: 'address',
    },
    {
        textIcon: '03',
        label: 'Additional Contacts',
        value: 'contact',
    },
]
