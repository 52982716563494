/* eslint-disable no-unused-vars */
import { ErrorMessage, useField } from 'formik'
import MeasurementInput from 'components/measurement-input/measurement-input.component'
import { IFormMeasurementInput } from './form-measurement-input.interface'

function FormMeasurementInput<T>({
    options = [],
    parentDivClassName = '',
    showErrorMessage = true,
    ...props
}: IFormMeasurementInput) {
    //formik
    const [field, meta, helpers] = useField(props.name)

    const selectedItem = options.find((x) => {
        return x.value === field.value.unit
    })

    const measurement = {
        qty: field?.value.qty || '',
        unit: selectedItem?.value || '',
    }

    const borderColorClass =
        meta.error && meta.touched ? 'themes-border-red' : ''

    const isError = !(!meta.error || (meta.error && !meta.touched))
    const errorClassMessage = isError ? '' : 'hidden'
    return (
        <div className={`${parentDivClassName}`}>
            <MeasurementInput
                options={options}
                measurement={measurement}
                setMeasurement={(change) => {
                    helpers.setValue(change)
                }}
                borderError={borderColorClass}
                {...props}
            />
            <div
                className={`ml-small-x text-size-XS text-red-500 ${!showErrorMessage ? 'hidden' : errorClassMessage}`}
            >
                <ErrorMessage name={props.name} component="p" />
            </div>
        </div>
    )
}

export default FormMeasurementInput
