/* eslint-disable no-unused-vars */
import SimpleTable from 'components/simple-table/simple-table.component'
import { IAccounts } from '../organisation-details.interface'
import { accountHeaders } from '../organisation-details.static'
import Input from 'components/input/input.component'
import ToggleSwitch from 'components/toggle-switch/toggle-switch.component'
import { IUseModal } from 'components/modal/modal.service'
import RemoveModalAddress from '../components/remove-address-modal.component'

const Accounts = ({
    data,
    detailsData,
    setDetailsData,
    modalService,
    setIsCreateAddress,
    removeData,
    removeModalService,
}: {
    data: IAccounts[]
    detailsData: IAccounts
    setDetailsData: (data: IAccounts) => void
    modalService: IUseModal
    setIsCreateAddress: (data: boolean) => void
    removeModalService: IUseModal
    removeData: () => void
}) => {
    return (
        <div className="flex gap-x-2">
            <div className="w-3/4 h-[calc(100vh-15.5rem)]">
                <SimpleTable<IAccounts>
                    headers={accountHeaders}
                    data={data}
                    isAddRow={true}
                    addRowLabel="Add New Account"
                    onRowClick={(data) => setDetailsData(data)}
                    customBuildParam4={() => ({
                        func1: () => {
                            removeModalService.openModalHandling()
                        },
                    })}
                    addRowClick={() => {
                        setIsCreateAddress(true)
                        modalService.openModalHandling()
                    }}
                    variant="gray"
                />
            </div>
            <div className="w-1/4 border rounded h-[calc(100vh-15.5rem)] flex flex-col">
                <div className="p-3">Account Details</div>

                <div className="border-b"></div>

                <div className="p-3 overflow-auto">
                    <div className="flex flex-col gap-3">
                        <ToggleSwitch
                            initialOn={detailsData.defaultAcc}
                            label="Default Account"
                            disabled={true}
                        />
                        <Input
                            label="Account Name"
                            value={detailsData.accountName}
                            disabled={true}
                        />
                        <Input
                            label={'Account Number'}
                            value={''}
                            disabled={true}
                        />
                        <Input
                            label="Routing Number"
                            value={detailsData.routingNumber}
                            disabled={true}
                        />
                        <Input
                            label="Currency"
                            value={detailsData.currency}
                            disabled={true}
                        />
                        <Input
                            label="Remark (Optional)"
                            value={''}
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
            <RemoveModalAddress
                modalService={removeModalService}
                type="removeAccount"
                onClick={() => {
                    removeData()
                }}
                data={detailsData.accountName}
            />
        </div>
    )
}

export default Accounts
