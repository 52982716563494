import { ITabItem } from 'components/tab/tab.interface'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { IResponseData } from 'common/common.interface'
import { Toast } from 'components/toast/toast.component'
import { SetupSiteDataDummy } from '../setup-site.dummy'
import SiteDetailsForm from './contents/site-details-form.component'
import DangerousGoodsDetailsForm from './contents/dangerous-goods-details-form.component'
import { ISite, createSiteInitial } from 'form-validation/poc/site.validation'
import { updateSite } from 'repository/poc/site.repository'

const useSetupSiteForm = () => {
    const navigate = useNavigate()

    const url = window.location.href.split('/')
    const id = url[url.length - 1]
    const decreasedID = parseInt(id, 10) - 1

    const [isEdit, setIsEdit] = useState(false)
    const [isSubmit, setIsSubmit] = useState<boolean>(false)
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'Site Details',
        key: 'status',
        value: 'siteDetails',
        className: 'brand',
    })

    const getContentDetailSite = (tabFilter: any) => {
        const items: any = {
            siteDetails: SiteDetailsForm(),
            dangerousGoodsDetails: DangerousGoodsDetailsForm(),
        }

        return items[tabFilter?.value]
    }

    const formikModule = useFormik<ISite>({
        initialValues: createSiteInitial,
        onSubmit: (values) => {
            submit({ data: values })
        },
    })

    const submit = async ({ data }: { data: ISite }) => {
        setIsSubmit(true)

        let payload: ISite = { ...data }

        let submitApi: IResponseData<any>

        submitApi = await updateSite(
            SetupSiteDataDummy.some((val) => val.siteID).toString() ?? '',
            payload,
        )

        if (submitApi.isSuccess) {
            Toast({
                header: 'Success!',
                message: 'success create user',
                type: 'success',
            })

            setTimeout(() => {
                navigate('/user')
            }, 100)
            setIsSubmit(false)
        } else {
            Toast({
                header: 'Failed!',
                message: submitApi?.message ?? 'failed to update user',
                type: 'error',
            })
            setIsSubmit(false)
        }
    }

    return {
        navigate,
        id,
        decreasedID,
        isSubmit,
        isEdit,
        tabFilter,
        formikModule,
        setIsEdit,
        setTabFilter,
        getContentDetailSite,
    }
}

export default useSetupSiteForm
