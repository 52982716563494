import { ISteps } from 'components/progression-step/progression-step.interface'

export const items: ISteps[] = [
    {
        icon: 'ri-file-list-3-fill',
        label: 'Basic Information',
        value: 'basicInformation',
    },
    {
        icon: 'ri-box-3-fill',
        label: 'Dangerous Goods Details',
        value: 'dangerousGoodsDetails',
    },
]
