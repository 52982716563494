import Dropdown from "components/dropdown/dropdown.component"
import { SetupSiteDataDummy } from "../setup-site.dummy"

const SetupSiteFilter = () => {
    return (
        <div>
            <div className="flex-col w-full mt-3 space-y-3">
                <Dropdown
                    options={
                        [
                            {
                                label: 'CS', 
                                value: 'CS',
                            }
                        ]
                    }
                    label="SITE CODE"
                    onClick={() => {}}
                    placeholder="All Site Codes"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
                <Dropdown
                    options={
                        SetupSiteDataDummy.map((item) => {
                            return {
                                label: item.siteID ?? '', 
                                value: item.siteID ?? '',
                            }
                        })
                    }
                    label="SITE ID"
                    onClick={() => {}}
                    placeholder="All Site IDs"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
                <Dropdown
                    options={
                        [
                            {
                                label: 'Central Site', 
                                value: 'Central Site',
                            }
                        ]
                    }
                    label="SITE NAME"
                    onClick={() => {}}
                    placeholder="All Site Names"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
                <Dropdown
                    options={
                        [
                            {
                                label: 'Majalengka, Indonesia', 
                                value: 'Majalengka, Indonesia',
                            }
                        ]
                    }
                    label="LOCATION"
                    onClick={() => {}}
                    placeholder="All Locations"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
                <Dropdown
                    options={
                        [
                            {
                                label: '60', 
                                value: '60',
                            }
                        ]
                    }
                    label="DIVISION"
                    onClick={() => {}}
                    placeholder="All Division"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
            </div>
        </div>
    )
}

export default SetupSiteFilter