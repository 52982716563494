import { ITabItem } from 'components/tab/tab.interface'
import { ITableColumn } from 'components/table/table.interface'
import { BookingDataDummy } from './bookings.dummy'

export const BookingStatusHeader: ITabItem[] = [
    {
        label: 'All Bookings',
        totalData: BookingDataDummy.length,
        key: 'status',
        value: 'allBookings',
        className: 'brand',
    },
    {
        label: 'Confirmed',
        totalData: BookingDataDummy.filter((x: any) => x.status === 'confirmed')
            .length,
        key: 'status',
        value: 'confirmed',
        className: 'green',
    },
    {
        label: 'Booked',
        totalData: BookingDataDummy.filter((x: any) => x.status === 'booked')
            .length,
        key: 'status',
        value: 'booked',
        className: 'yellow',
    },
    {
        label: 'Wait Listed',
        totalData: BookingDataDummy.filter(
            (x: any) => x.status === 'waitListed',
        ).length,
        key: 'status',
        value: 'waitListed',
        className: 'red',
    },
]

export const BookingSummaryHeader: ITableColumn[] = [
    {
        label: 'Shipment ID',
        accessor: 'shipmentID',
    },
    {
        label: 'Goods Description',
        accessor: 'goodsDescription',
    },
    {
        label: "Shipper's Ref",
        accessor: 'shippersReference',
    },
    {
        label: 'Origin',
        accessor: 'origin',
    },
    {
        label: 'Destination',
        accessor: 'destination',
    },
    {
        label: 'Packs',
        accessor: 'packs',
    },
    {
        label: 'Weight Measure',
        accessor: 'weightMeasure',
    },
    {
        label: 'Volume Measure',
        accessor: 'volumeMeasure',
    },
    {
        label: 'Master Bill',
        accessor: 'masterBill',
    },
    {
        label: 'Load Port',
        accessor: 'loadPort',
    },
]
