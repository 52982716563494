const PreviewDocument = ({
    fileUrl = 'https://drive.google.com/file/d/18Yzm-UN1SCqt1CdoHabSA_degQ2lVWwJ/preview',
}: {
    fileUrl?: string
}) => {
    return (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
            src={fileUrl}
            style={{
                width: '100%',
                height: '100%',
                border: 'none',
            }}
        />
    )
}

export default PreviewDocument
