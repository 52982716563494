import Button from 'components/button/button.component'
import './user-access-detail.style.css'
import AvatarCircle from 'components/avatar-circle/avatar-circle.component'
import { UserSettingIcon } from './components/user-settings-icon.component'
import { useNavigate } from 'react-router-dom'
import Table from 'components/table/table.component'
import { IUserActivityLogs } from '../user-access.interface'
import { userActivityHeaders } from '../user-access.static'
import { dummyActivittyUser } from '../user-access.dummy'
import SuspendUserModal from '../component/suspend-user-modal.component'
import useUserAccessDetail from './user-access-detail.service'
import { Toast } from 'components/toast/toast.component'

const UserAccessDetail = () => {
    const navigate = useNavigate()
    const { sendEmail, modalConfirmationService } = useUserAccessDetail()
    return (
        <>
            <div
                className={`user-detail transition-opacity duration-500 !overflow-visible`}
            >
                <div className="content-1 content-full-height">
                    {/* Flex 1, 2 rows */}

                    <div className="content-1-A container-global">
                        {/* Image Session */}
                        <div className="flex justify-between items-centers gap-4">
                            <AvatarCircle name={'Alice Johnson S'} size={50} />
                            <div className="flex-1 flex flex-col leading-none ">
                                <div className="text-size-M pb-1 pt-3">
                                    Alice Johnson
                                </div>
                                <div className="text-size-XS">Admin User</div>
                            </div>
                            <UserSettingIcon
                                isActive={true}
                                suspendFunction={() => {
                                    modalConfirmationService.openModalHandling()
                                }}
                            />
                        </div>

                        {/* Border */}
                        <div className="border-t mx-[-1rem] my-4"></div>

                        {/* Detail User Session */}
                        <div className="flex-1 flex">
                            <div className="flex-1">
                                <div className="flex flex-col gap-4">
                                    <ValueLabel
                                        label={'User ID'}
                                        value={'EMP001'}
                                    />
                                    <div className="flex justify-between">
                                        <ValueLabel
                                            label={'Email Address'}
                                            value={'alice.johnson@example.com'}
                                        />
                                        <div
                                            className="flex gap-2 items-center themes-text-gray-v6 cursor-pointer"
                                            onClick={() => {
                                                sendEmail()
                                            }}
                                        >
                                            Contact User
                                            <i className="ri-mail-line"></i>
                                        </div>
                                    </div>
                                    <ValueLabel
                                        label={'Phone Number'}
                                        value={'+61000002002134'}
                                    />
                                    <ValueLabel
                                        label={'Job Title'}
                                        value={'Logistics Coordinator'}
                                    />
                                    <ValueLabel
                                        label={'Job Category'}
                                        value={'Logistics'}
                                    />
                                    <ValueLabel
                                        label={'Organisation Name'}
                                        value={'BALI ORG PTY LTD'}
                                    />
                                    <ValueLabel
                                        label={'Status'}
                                        value={'ACTIVE'}
                                    />
                                    <ValueLabel
                                        label={'Last Active'}
                                        value={'12/20/24, 15:00'}
                                    />
                                    <ValueLabel
                                        label={'User Role'}
                                        value={'REGULAR USER'}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Border */}
                        <div className="border-t mx-[-1rem] my-4"></div>
                        <div className="flex justify-between">
                            <Button
                                label="Back"
                                variant="default"
                                className="w-btnSmallWidth"
                                onClick={() => {
                                    navigate('/manage-users')
                                }}
                            />
                            <Button
                                variant="brand-inverse"
                                className="!w-[160px]"
                                label={'Edit User'}
                                onClick={() => {
                                    navigate('/manage-users/update/EM001')
                                }}
                                useUpperCase={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="content-2  container-global content-full-height !p-0">
                    {/* Flex 2, 1 rows */}

                    <div className="flex gap-4 pt-4 pl-4">
                        <div className="flex-grow text-size-L pb-1 pt-1 font-bold">
                            USER ACTIVITY LOGS
                        </div>
                    </div>

                    {/* Border */}
                    <div className="border-t mx-[-1rem] mt-4"></div>

                    <div className="flex-grow h-[calc(100vh-12.4rem)] items-center">
                        <Table<IUserActivityLogs>
                            headers={userActivityHeaders}
                            data={dummyActivittyUser}
                            moduleTitle={'User'}
                            usePagination={false}
                            enableExport={false}
                            loading={false}
                        />
                    </div>
                </div>

                <SuspendUserModal
                    data={{ name: 'Alice Johnson' }}
                    modalService={modalConfirmationService}
                    isActive={true}
                    onSubmit={function (): void {
                        Toast({
                            header: 'Contact Marked as Non-Active',
                            message:
                                'Contact successfully marked as non-active.',
                            type: 'success',
                        })
                    }}
                />
            </div>
        </>
    )
}

const ValueLabel = ({
    label = ' - ',
    value = ' - ',
}: {
    label?: string
    value?: string
}) => {
    const labelShow = label === '' || !label ? '-' : label
    const valueShow = value === '' || !value ? '-' : value
    return (
        <div className="leading-none ">
            <p className="!text-size-XS font-light pb-1">{labelShow}</p>
            <p className="!text-size-M font-normal">{valueShow}</p>
        </div>
    )
}

export default UserAccessDetail
