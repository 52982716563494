import DatePicker from "components/date-picker/date-picker.component";
import Textarea from "components/text-area/text-area.component";

const DatesAndInstructions = () => {
  return (
    <div className="flex flex-col w-full p-4 gap-4 h-[calc(100vh-280px)] overflow-y-auto">
      <div className="flex">
        <span className=" text-size-L">Goods Details</span>
      </div>
      <div className="flex w-full gap-x-4">
        <div className="flex w-1/2">
          <DatePicker
            isRange={false}
            className="w-full"
            placeholder="Scheduled Departed Date"
            iconPosition="left"
          />
        </div>
        <div className="flex w-1/2">
          <DatePicker
            isRange={false}
            className="w-full"
            placeholder="Expected Arrival Date"
          />
        </div>
      </div>
      <div className="border-b border-logistical-gray-ver7" />
      <div className="flex w-full gap-x-4">
        <div className="flex w-1/2">
          <Textarea
            label={"HANDLING INSTRUCTIONS"}
            placeholder={"Add handling instructions"}
            className="!h-[152px]"
          />
        </div>
        <div className="flex w-1/2">
          <Textarea
            label={"PACKAGING INSTRUCTIONS"}
            placeholder={"Add packaging instructions"}
            className="!h-[152px]"
          />
        </div>
      </div>
      <div className="flex w-full gap-x-4">
        <Textarea
          label={"ADDITIONAL NOTES OR COMMENTS"}
          placeholder={"Add additional notes"}
          className="!h-[152px]"
        />
      </div>
    </div>
  );
};
export default DatesAndInstructions;
