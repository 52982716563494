
import Dropdown from 'components/dropdown/dropdown.component'
import Input from 'components/input/input.component'
import Textarea from 'components/text-area/text-area.component'

const Address = () => {
    return (
        <div className="w-full p-4 gap-4 h-[calc(100vh-280px)] overflow-y-auto">
            <div className='flex w-full gap-4'>

                <div className="flex-col w-1/3 space-y-4 ">
                    <span className="text-size-M font-bold">Shipper</span>
                    <Dropdown
                        label={'Select Shipper'}
                        options={[
                            {
                                label: 'IFB Freightbridge',
                                value: 'IFB Freightbridge',
                            },
                        ]}
                        placeholder="Select Shipper Company"
                    />

                </div>
                <div className="flex-col w-1/3 space-y-4">
                    <span className="text-size-M font-bold">Consignee</span>
                    <Dropdown
                        label={'Select Consignee'}
                        options={[
                            {
                                label: 'OneByOne Logistical',
                                value: 'OneByOne Logistical',
                            },
                        ]}
                        placeholder="Select Consignee Company"
                    />

                </div>

            </div>


            <div className='flex pt-4 w-full gap-4'>

                <div className="flex-col w-1/2 space-y-4 ">
                    <span className="text-size-M font-bold"> Origin Location </span>
                    <Dropdown
                        label={'Select Company'}
                        options={[
                            {
                                label: 'IFB Freightbridge',
                                value: 'IFB Freightbridge',
                            },
                        ]}
                        placeholder="Select Company"
                    />
                    <div className='flex gap-4'>
                        <Input label="Postal Code" placeholder="Input Postal Code" />
                        <Input label="City" placeholder="Input City" />
                    </div>
                    <div className='flex gap-4'>
                        <Input label="State/Province" placeholder="Input State/Province" />
                        <Input label="Country" placeholder="Input Country" />
                    </div>
                    <Textarea label='Address Detail' className='!h-[120px]' />
                    <div className='flex gap-4'>
                        <div className=' !w-[120px]'>
                            <Input label="Phone Code" className='flex-initial' />

                        </div>
                        <Input label="Phone Number" placeholder="Input Phone Number" />
                    </div>
                    <Input label="Email Address" placeholder="Input Email Address" />

                </div>

                <div className="flex-col w-1/2 space-y-4 ">
                    <span className="text-size-M font-bold"> Destination Location </span>
                    <Dropdown
                        label={'Select Company'}
                        options={[
                            {
                                label: 'IFB Freightbridge',
                                value: 'IFB Freightbridge',
                            },
                        ]}
                        placeholder="Select Company"
                    />
                    <div className='flex gap-4'>
                        <Input label="Postal Code" placeholder="Input Postal Code" />
                        <Input label="City" placeholder="Input City" />
                    </div>
                    <div className='flex gap-4'>
                        <Input label="State/Province" placeholder="Input State/Province" />
                        <Input label="Country" placeholder="Input Country" />
                    </div>
                    <Textarea label='Address Detail' className='!h-[120px]' />
                    <div className='flex gap-4'>
                        <div className=' !w-[120px]'>
                            <Input label="Phone Code" className='flex-initial' />

                        </div>
                        <Input label="Phone Number" placeholder="Input Phone Number" />
                    </div>
                    <Input label="Email Address" placeholder="Input Email Address" />

                </div>

            </div>

        </div>
    )
}

export default Address
