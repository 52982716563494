import axios, { AxiosResponse } from 'axios'
import { IBlob, IFetch, IFetchOptions, IPost, IRoute } from './common.interface'
import moment from 'moment'
import { useEffect } from 'react'
import { removeSession } from 'pages/common/login/login.slice'
import { getConfig } from 'config/config.service'
import { logisticalRoutes } from './routes/logistical.routes'
import { IConfig } from 'config/config.interface'
import { limajariRoutes } from './routes/limajari.routes'
import { pocRoutes } from './routes/poc.routes'

const DATE_FORMAT = process.env.REACT_APP_DATE_FORMAT as string
const DATE_TIME_FORMAT = process.env.REACT_APP_DATE_TIME_FORMAT as string

const getBaseUrl = () => {
    const config = getConfig()
    return config.apiUrl
}
export const getClient = () => {
    const config: IConfig = getConfig()
    return config.client
}

export const fetch = async <D, T>(props: IFetch<T>) => {
    try {
        const response: AxiosResponse<D> = await axios.get(
            getBaseUrl() + props.endpoint,
            {
                params: props.params,
            },
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            removeSession()
            throw new Error('Unauthorized Access: Please log in')
        } else {
            throw new Error('No Data Available')
        }
    }
}

export const fetchOptions = async <D>(props: IFetchOptions) => {
    try {
        const response: AxiosResponse<D> = await axios.get(
            getBaseUrl() + props.endpoint,
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            removeSession()
            throw new Error('Unauthorized Access: Please log in')
        } else {
            throw new Error('No Data Available')
        }
    }
}

export const post = async <D, T>(props: IPost<T>) => {
    const response: AxiosResponse<D> = await axios.post(
        getBaseUrl() + props.endpoint,
        props?.payload,
        {
            headers: {
                'Content-Type': props?.isFormData
                    ? 'multipart/form-data'
                    : 'application/json',
            },
            params: props?.params,
        },
    )

    if (response.status === 401) {
        removeSession()
    }
    return response.data
}

export const put = async <D, T>(props: IPost<T>) => {
    const response: AxiosResponse<D> = await axios.put(
        getBaseUrl() + props.endpoint,
        props?.payload,
    )

    if (response.status === 401) {
        removeSession()
    }
    return response.data
}

export const deleteRequest = async <D, T>(props: IFetch<T>) => {
    console.log('props', props)

    const response: AxiosResponse<D> = await axios.delete(
        getBaseUrl() + props.endpoint,
        { params: props.params },
    )

    if (response.status === 401) {
        removeSession()
    }
    return response.data
}

export const fecthBlob = async <D, T>(props: IBlob<T>) => {
    try {
        const response: AxiosResponse<D> = await axios.get(
            getBaseUrl() + props.endpoint,
            {
                params: props?.params,
                responseType: 'blob',
            },
        )
        return response
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            removeSession()
            throw new Error('Unauthorized Access: Please log in')
        } else {
            throw new Error('No Data Available')
        }
    }
}

export const formatDateTime = (dateString: string) => {
    const formattedDate = moment(dateString).format(DATE_TIME_FORMAT)
    return formattedDate
}

export const formatDate = (dateString: string) => {
    const formattedDate = moment(dateString).format(DATE_FORMAT)
    return formattedDate
}

export const getModuleParentId = () => {
    let parentIds: string[] = []

    getRoutesByClient().forEach((dt) => {
        if (!dt.parentId) {
            return
        }

        parentIds.push(dt.parentId)

        let sub: IRoute[] | undefined = dt.sub
        if (sub) {
            sub?.forEach((dtSub) => {
                if (!dtSub.parentId) {
                    return
                }
                parentIds.push(dtSub.parentId)
            })
        }
    })
    return parentIds
}

export const toCamelCase = (str: string) => {
    // Split kata-kata berdasarkan spasi atau underscore
    const words = str.split(/\s|_/)

    // Ubah setiap kata menjadi camelCase
    const camelCaseWords = words.map((word, index) => {
        // Pada kata pertama, gunakan huruf kecil
        if (index === 0) {
            return word.toLowerCase()
        }

        // Pada kata lainnya, gunakan huruf besar di awal
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })

    // Gabungkan kata-kata menjadi satu string
    return camelCaseWords.join('')
}

export const revertToTitleCase = (str: string) => {
    // Breaks a string into an array of words
    let words = str.match(/[A-Za-z][a-z]*/g) || []

    // Combine words with spaces and change the first letter of each word to uppercase
    return words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}

export const numberWithCommas = (x: string) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const capitalizeFirstLetter = (str: string) => {
    const words = str.split(/\s|_/)

    const CapitalizedFirstLetter = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })

    return CapitalizedFirstLetter.join(' ')
}

export const removeUnderscores = (text: string) => text.replace(/_/g, ' ')

/**
 * Hook that alerts clicks outside of the passed refCombine words with spaces and change the first letter of each word to uppercase
 */
export function useOutsideClickHandling(ref: any, func: () => void) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                func()
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}

// copy text to clipboard
export const copyTextToClipboard = (
    id: string,
    iconClassNames: string = '',
) => {
    // Mengambil elemen berdasarkan ID
    const getContainer = document.getElementById(id)

    // Mengambil nilai teks dari elemen div
    const inputElement: any = getContainer
        ? getContainer.querySelector('input')
        : null

    const textValue = inputElement
        ? (inputElement as HTMLInputElement).value
        : (getContainer?.textContent as string)

    // Menyalin teks ke clipboard
    navigator.clipboard
        .writeText(textValue)
        .then(() => {
            // Mengubah ikon menjadi tanda centang setelah berhasil disalin ke clipboard
            const iconElement =
                getContainer?.querySelector('.ri-file-copy-line')
            console.log(iconElement)

            if (iconElement) {
                iconElement.className = `${iconClassNames} ri-check-line`

                // Setelah 1.5 detik, kembalikan ikon ke ikon semula
                setTimeout(() => {
                    iconElement.className = `${iconClassNames} ri-file-copy-line cursor-pointer`
                }, 1500)
            }
        })
        .catch((err) => {
            console.error('Failed to copy text: ', err)
        })
}

export function changeInputValueToZero(id: string, value: string): void {
    // Mendapatkan referensi ke elemen input berdasarkan ID
    const myInput: HTMLInputElement | null = document.getElementById(
        id,
    ) as HTMLInputElement

    // Memastikan elemen ditemukan sebelum mengubah nilai
    if (myInput && value === '') {
        // Mengubah nilai elemen input
        myInput.value = '0'
    }
}

export const getRoutesByClient = () => {
    const client = getClient()
    let routes: IRoute[] = []

    switch (client) {
        case 'limajari':
            routes = limajariRoutes
            return routes
        case 'poc':
            routes = pocRoutes
            return routes
        case 'LOGISTICAL':
            routes = logisticalRoutes
            return routes
        default:
            routes = logisticalRoutes
            return routes
    }
}

export const mapPath = (path: string) => {
    if (typeof path !== 'string') return []
    const pathArray = path.split('/')?.filter((data) => data)
    return pathArray
}

export const openEmailClient = (emailAddress: string) => {
    var subject = 'Subject'
    var body = '--- Body ---'

    var mailtoUrl =
        'mailto:' +
        encodeURIComponent(emailAddress) +
        '?subject=' +
        encodeURIComponent(subject) +
        '&body=' +
        encodeURIComponent(body)

    window.open(mailtoUrl)
}
