import ProgressBar from 'components/progress-bar/progress-bar.component'
import StatusCard from 'components/status-card/status-card.component'
import { ITabItem } from 'components/tab/tab.interface'
import { ITableColumn } from 'components/table/table.interface'
import { SetupSiteDataDummy } from './setup-site.dummy'

export const HeaderStatusData: ITabItem[] = [
    {
        label: 'All Sites',
        totalData: SetupSiteDataDummy.length,
        key: 'all',
        value: 'all',
        className: 'brand',
    },
]
export const SetupSiteSummaryHeader: ITableColumn[] = [
    {
        label: 'Site Code',
        accessor: 'siteCode',
        width: 1,
    },
    {
        label: 'Site Name',
        accessor: 'siteName',
        width: 1,
    },
    {
        label: 'Location',
        accessor: 'location',
        width: 1,
    },
    {
        label: 'Site ID',
        accessor: 'siteID',
        width: 1,
    },
    {
        label: 'Division',
        accessor: 'division',
        width: 1,
    },
    {
        label: 'Inventory Level',
        accessor: 'inventoryLevel',
        customBuild: (data) => {
            const inventoryLevel = parseInt(data)
            return (
                <ProgressBar
                    total={15}
                    current={inventoryLevel}
                    variant="outline-blue"
                />
            )
        },
        width: 1,
    },
    {
        label: 'Status',
        accessor: 'status',
        customBuild: (data) => <StatusCard status={data} />,
        width: 1,
    },
    {
        label: 'Last Updated',
        accessor: 'lastUpdated',
    },
]
