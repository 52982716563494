import { ISTColumn } from 'components/simple-table/simple-table.interface'
import { ITabItem } from 'components/tab/tab.interface'
import { IQuote } from './quotes.interface'
import { quotesDummy } from './quotes.dummy'
import { IMeta } from 'common/common.interface'
import { Toast } from 'components/toast/toast.component'
import ButtonPreviewDocument from 'components/button-preview-document/button-preview-document.component'
import PreviewQuote from './new-quote/components/preview-quote.component'
import Button from 'components/button/button.component'

export const quotesStatusHeader: ITabItem[] = [
    {
        label: 'All Quotes',
        totalData: quotesDummy.length,
        key: 'status',
        value: 'allQuotes',
        className: 'brand',
    },
    {
        label: 'SEA',
        totalData: quotesDummy.filter((x) => x.mode === 'sea').length,
        key: 'status',
        value: 'sea',
        className: 'brand',
    },
    {
        label: 'AIR',
        totalData: quotesDummy.filter((x) => x.mode === 'air').length,
        key: 'status',
        value: 'air',
        className: 'brand',
    },
]

export const quoteHeaders: ISTColumn<IQuote>[] = [
    {
        label: 'Mode',
        accessor: 'mode',
        customBuild: (data) => {
            let iconMode: string
            let labelMode: string
            switch (data) {
                case 'air':
                    iconMode = 'ri-plane-line'
                    labelMode = 'AIR'
                    break
                case 'sea':
                    iconMode = 'ri-ship-line'
                    labelMode = 'SEA'
                    break
                default:
                    iconMode = 'ri-plane-line'
                    labelMode = 'AIR'
            }

            return (
                <div className="flex gap-2">
                    <i className={`${iconMode}`}></i> {labelMode}
                </div>
            )
        },
    },
    {
        label: 'Quote ID',
        accessor: 'quoteId',
    },
    {
        label: 'Client',
        accessor: 'client',
    },
    {
        label: 'Origin',
        accessor: 'origin',
    },
    {
        label: 'Destination',
        accessor: 'destination',
    },
    {
        label: 'Container Mode',
        accessor: 'containerMode',
    },
    {
        label: 'Currency',
        accessor: 'currency',
    },
    {
        label: 'Volumetric Weight',
        accessor: 'volumetricWeight',
    },
    {
        label: 'Actual Weight',
        accessor: 'actualWeight',
    },
    {
        label: 'Branch',
        accessor: 'branch',
    },
    {
        label: 'Created By',
        accessor: 'createdBy',
    },
    {
        label: 'Date Created',
        accessor: 'dateCreated',
    },
    {
        label: 'Last Updated',
        accessor: 'lastUpdated',
    },
    {
        label: '',
        accessor: 'lastUpdated',
        customBuild: () => (
            <div>
                <ButtonPreviewDocument
                    onClick={() => {}}
                    icon="ri-file-pdf-2-line"
                    label="PREVIEW PDF"
                    componentPdf={<PreviewQuote />}
                    isPreview={true}
                />
                <Button label="Remove" onClick={() => {}} />
            </div>
        ),
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}

export const handleFeatureInDevelopment = () =>
    Toast({
        header: 'Feature in Development',
        message:
            'This feature is currently under development and will be available soon!',
        type: 'info',
    })
