import GoogleMap from 'components/google-map/google-map.component'
import { useState } from 'react'
import { getShipmentTracking, shipmentTrackingHeader } from './row2.dummy'
import Header from '../components/header.component'
import FilterSection from '../components/filter-section.component'
import { faker } from '@faker-js/faker'
interface IPoint {
    name: string;
    lat: number;
    lng: number;
    key: string;
    status: boolean;
}

function MapWithTable() {
    const [isFilter, setIsFilter] = useState<boolean>(false)

    const PointMap = (): IPoint[] => {
        const data = [];

        // Thailand
        for (let i = 0; i < 20; i++) {
            let name = `S0000${faker.number.int({ min: 2000, max: 5000 })}`;
            let lat = faker.location.latitude({ max: 15.870032, min: 12.670032 });
            let lng = faker.location.longitude({ max: 100.992541, min: 88.192541 });
            let key = `THAI-${i}`;
            let status = true;
            data.push({ name: name, lat: lat, lng: lng, key: key, status: status });
        }

        // INDONESIA
        for (let i = 0; i < 20; i++) {
            let name = `S0000${faker.number.int({ min: 2000, max: 5000 })}`;
            let lat = faker.location.latitude({ max: 6.789275, min: -0.789275 });
            let lng = faker.location.longitude({ max: 113.921327, min: 100.921327 });
            let key = `INDO-${i}`;
            let status = true;
            data.push({ name: name, lat: lat, lng: lng, key: key, status: status });
        }

        // Australia
        for (let i = 0; i < 20; i++) {
            let name = `S0000${faker.number.int({ min: 2000, max: 5000 })}`;
            let lat = faker.location.latitude({ max: -25.274398, min: -29.274398 });
            let lng = faker.location.longitude({ max: 133.775136, min: 132.775136 });
            let key = `AUS-${i}`;
            let status = true;
            data.push({ name: name, lat: lat, lng: lng, key: key, status: status });
        }

        return data;
    };

    return (
        <div className="grid lg:grid-rows-12 md:grid-rows-12 h-full">
            {/* Title */}
            <Header
                className="lg:row-span-1 md:row-span-1 px-2"
                title={`Shipment Tracking (Origin)`}
                filter={`Last 7 Days (17/03/2024 - 23/03/24)`}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
            />

            {isFilter ? (
                <div className='lg:!row-span-5 md:row-span-5 px-2 lg:!h-auto md:!h-[30vh]'>
                    <FilterSection
                        className='grid gap-2 mt-2'
                        displayedInform={true}
                        filterBy={true}
                        dateRange={true}
                        configure={true}
                        selectedTable={true}
                    />
                </div>
            ) : (
                <>
                    {/* Maps */}
                    <div className="lg:row-span-5 md:row-span-5 lg:!h-auto md:!h-[30vh]">
                        <GoogleMap points={PointMap()} />
                    </div>
                </>
            )}

            {/* Table */}
            <div className="lg:row-span-6 md:row-span-6 overflow-y-auto scrollable-content lg:h-auto md:h-[35vh]">
                <table className="table-auto w-full">
                    <thead className="text-gray-400 themes-bg-white top-0 sticky">
                        <tr className="text-xs border-b themes-border-color h-10 text-[16px]">
                            {shipmentTrackingHeader.map((item, index) => {
                                return (
                                    <td
                                        className="first:pl-2 last:pr-2"
                                        key={index}
                                    >
                                        {item.label}
                                    </td>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody className="text-gray-700">
                        {getShipmentTracking().map(
                            (item: any, index: number) => {
                                return (
                                    <tr
                                        key={index}
                                        className="h-10 w-full border-b border-gray-200 items-center"
                                    >
                                        {shipmentTrackingHeader.map(
                                            (id, index2) => {
                                                return (
                                                    <td
                                                        key={
                                                            index + '-' + index2
                                                        }
                                                        className={`${id.value === 'id' && 'text-[#266EDC]'} first:pl-2 last:pr-2 text-[16px] font-normal font-['Basis Grotesque Arabic Pro']`}
                                                    >
                                                        {id.value === 'mode' ? (
                                                            <i
                                                                className={`${item[id.value]} text-gray-400`}
                                                            />
                                                        ) : (
                                                            item[id.value]
                                                        )}
                                                    </td>
                                                )
                                            },
                                        )}
                                    </tr>
                                )
                            },
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default MapWithTable
