import Button from 'components/button/button.component'
import FormDropdown from 'components/form-dropdown/form-dropdown.component'
import FormInput from 'components/form-input/form-input.component'
import FormTextArea from 'components/form-textarea/form-textarea.component'
import Modal from 'components/modal/modal.component'
import { IUseModal } from 'components/modal/modal.service'
import ToggleSwitch from 'components/toggle-switch/toggle-switch.component'
import { FormikContextType, FormikProvider } from 'formik'

const CreateOrEditAddressModal = ({
    modalService,
    formik,
    isCreate,
}: {
    modalService: IUseModal
    formik: FormikContextType<any>
    isCreate: boolean
}) => {
    return (
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3  px-0">
            <FormikProvider value={formik}>
                <div>
                    <div className="flex justify-between px-3">
                        <div className="flex items-center font-bold text-size-L">
                            {isCreate ? 'CREATE NEW ADDRESS' : 'EDIT ADDRESS'}
                        </div>
                        <Button
                            onClick={() => modalService.closeModalHandling()}
                            icon="ri-close-fill"
                            className="border-none w-btnIconWidth"
                        />
                    </div>
                    <div className="border-b"></div>
                    <div className="flex flex-col p-3 gap-3">
                        <FormInput
                            label="Address Name"
                            name="addressName"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Address Name"
                        />
                        <FormTextArea
                            label="Address 1"
                            name="address1"
                            placeholder="Enter Address"
                        />
                        <FormTextArea
                            label="Address 2 (Optional)"
                            name="address2"
                            placeholder="Enter Address"
                        />
                        <div className="w-full flex gap-3">
                            <FormDropdown
                                name="countryOrRegion"
                                label="Country/Region"
                                placeholder="Select Country"
                                options={[
                                    {
                                        label: 'INDONESIA',
                                        value: 'INDONESIA',
                                    },
                                ]}
                                parentDivClassName="w-1/2 !mb-0"
                            />
                            <FormDropdown
                                name="state"
                                label="State"
                                placeholder="Select State"
                                options={[
                                    {
                                        label: 'BALI',
                                        value: 'BALI',
                                    },
                                ]}
                                parentDivClassName="w-1/2 !mb-0"
                            />
                        </div>
                        <div className="w-full flex gap-3">
                            <FormDropdown
                                name="city"
                                label="City"
                                placeholder="Select City"
                                options={[
                                    {
                                        label: 'DENPASAR',
                                        value: 'DENPASAR',
                                    },
                                ]}
                                parentDivClassName="w-1/2 !mb-0"
                            />
                            <FormDropdown
                                name="postCode"
                                label="Postcode"
                                placeholder="Select Postcode"
                                options={[
                                    {
                                        label: '309218',
                                        value: '309218',
                                    },
                                ]}
                                parentDivClassName="w-1/2 !mb-0"
                            />
                        </div>
                        <FormInput
                            label="Unloco"
                            name="unloco"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Unloco"
                        />
                        <FormInput
                            label="Related City/Port (Optional)"
                            name="relatedCityOrPort"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Enter City/Port"
                        />
                        <ToggleSwitch
                            label={'Active Address'}
                            initialOn={true}
                        />
                    </div>
                    <div className="border-b"></div>
                    <div className="px-3 mt-3">
                        <Button
                            onClick={() => {
                                modalService.closeModalHandling()
                                formik.submitForm()
                            }}
                            label={
                                isCreate ? 'CREATE ADDRESS' : 'UPDATE ADDRESS'
                            }
                            variant="brand"
                            className="w-full"
                        />
                    </div>
                </div>
            </FormikProvider>
        </Modal>
    )
}

export default CreateOrEditAddressModal
