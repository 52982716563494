import { IUseModal } from 'components/modal/modal.service'
import { eDocumentationHeader } from '../shipments-details.static'
import SimpleTable from 'components/simple-table/simple-table.component'

const EDocumentation = ({
    modalService,
    modalComponent,
}: {
    modalService: IUseModal
    modalComponent: JSX.Element
}) => {
    return (
        <>
            <SimpleTable
                headers={eDocumentationHeader}
                data={[]}
                title={<>eDocument</>}
                isAddRow={true}
                addRowClick={() => {
                    modalService.openModalHandling()
                }}
            />

            {modalComponent}
        </>
    )
}

export default EDocumentation
