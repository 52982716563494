import Button from 'components/button/button.component'
import ProgressionStep from 'components/progression-step/progression-step.component'
import { Toast } from 'components/toast/toast.component'
import { items } from './setup-site-create.static'
import useSetupSiteCreate from './setup-site-create.service'

const CreateSite = () => {
    const {
        stepFilter,
        setStepFilter,
        getContentCreateBooking,
        nextCompo,
        previousCompo,
        navigate,
    } = useSetupSiteCreate()
    return (
        <div
            className={`booking-create flex flex-wrap gap-x-4 h-[calc(100vh-105px)] transition-opacity duration-500`}
        >
            <div className="flex-grow flex-col md:gap-y-4 gap-x-4 md:w-[calc(100vw-900px)] bg-white container-border container-global overflow-x-hidden overflow-y-hidden">
                <div className="flex w-full justify-between items-center p-4">
                    <div className="flex">
                        <span className="flex text-size-L font-bold">
                            ADD NEW SITE
                        </span>
                    </div>
                    <div className="flex">
                        <ProgressionStep
                            steps={items}
                            onChange={(step) => setStepFilter(step)}
                            selectedItem={stepFilter}
                        />
                    </div>
                    <div className="flex">
                        <Button
                            onClick={() => {
                                Toast({
                                    header: 'NEW SITE ADDED',
                                    message: `SITE 16 is successfully added`,
                                    type: 'success',
                                })
                                navigate('/site')
                            }}
                            className="w-btnRegularWidth"
                            label="ADD SITE"
                            variant="brand-inverse"
                        />
                    </div>
                </div>
                <div className="border-b border-logistical-gray-ver7" />
                {getContentCreateBooking(stepFilter)}
                <div className="border-b border-logistical-gray-ver7" />
                <div className="flex w-full p-4 justify-between">
                    <div className="flex gap-4">
                        <Button
                            className="w-btnRegularWidth !text-size-S"
                            label={'BACK'}
                            onClick={() => {
                                navigate('/site')
                            }}
                            variant="default"
                        />
                    </div>
                    <div className="flex gap-4">
                        <Button
                            className="w-btnRegularWidth"
                            label="PREVIOUS"
                            onClick={() => {
                                previousCompo()
                            }}
                            icon="ri-arrow-left-line"
                            isDisabled={
                                stepFilter.value === 'basicInformation'
                                    ? true
                                    : false
                            }
                            variant="brand-inverse"
                        />
                        <Button
                            className="w-btnRegularWidth"
                            label="NEXT"
                            onClick={() => {
                                nextCompo()
                            }}
                            iconSuffix="ri-arrow-right-line"
                            isDisabled={
                                stepFilter.value === 'dangerousGoodsDetails'
                                    ? true
                                    : false
                            }
                            variant="brand-inverse"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateSite
