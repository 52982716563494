/* eslint-disable no-unused-vars */
import EstimationInfo from 'components/estimation-info/estimation-info'
import { useState } from 'react'
import { ITableExpandableRow, ITrackDetail } from '../table-expandable-detail.interface'

export function TableExpandableRow({
    ...props
}: ITableExpandableRow) {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const IconRow = props.data.typeTransport === 'SEA' ? ' ri-ship-line' : props.data.typeTransport === 'AIR' ? 'ri-plane-line' : 'ri-truck-line'
    return (
        <>
            <div className='grid grid-cols-12 justify-between px-[30px] py-[15px] border-b'>
                <div className='col-span-5'>
                    <div className='flex item-center'>
                        <i className={`${IconRow} text-[28px] themes-text-brand-v5`} />
                        <div className='ml-5'>
                            <div className='text-[18px] font-bold'> {props.data.idShipment}</div>
                            <div className='text-[14px]'> {props.data.description}</div>
                        </div>
                    </div>
                </div>
                <div className='col-span-7 flex items-center'>
                    <div className='flex-grow'>
                        <EstimationInfo
                            initialCountryName={props.data.initialCountryName}
                            initialCountryCode={props.data.initialCountryCode}
                            destinationCountryName={props.data.destinationCountryName}
                            destinationCountryCode={props.data.destinationCountryCode}
                            ETD={props.data.ETD}
                            ETA={props.data.ETA}
                            iconMap={false}
                        />
                    </div>

                    <i className="ri-more-2-line mx-5" />
                    <i
                        className={`ri-arrow-down-s-line transform cursor-pointer ${isExpanded ? 'rotate-180' : 'rotate-0'} transition-transform duration-300 ease-in-out`}
                        onClick={() => { setIsExpanded(!isExpanded) }}
                    />

                </div>
            </div>
            <div
                className={`overflow-hidden   grid grid-cols-12
                                            ${isExpanded ? 'h-[300px] transition-height duration-300 px-[30px] py-[15px] border-b' : 'h-0 transition-height duration-300'}`}
            >
                <div className='leading-5 col-span-6'>
                    {props.summaryDetail.map((item: any, idx: number) => {
                        return (
                            <div key={`item-detail-${idx}`} className='text-[14px] grid grid-cols-2'>
                                <div className='font-bold themes-text-gray-v6'>{item.label}:</div>
                                <div>{props.data.detail[item.accessor]}</div>
                            </div>
                        )
                    })}
                </div>
                <div className='flex flex-col col-span-6'>
                    {props.data.track.map((data: ITrackDetail, idx: number) => {
                        return (
                            <div key={`data-track-${idx}`} className='flex items-start relative'>
                                <div className='mr-2 flex flex-col items-center'>
                                    <div className='rounded-full w-2 h-2 bg-blue-500 line-through z-10' />
                                    {idx !== props.data.track?.length - 1 && <div className='absolute top-0 bottom-0 w-[2px] themes-bg-gray-v2' />}
                                </div>
                                <div className='text-[14px] mt-[-4px] pb-5'>
                                    <div className='font-bold'>{data.label}</div>
                                    <div>{data.desc}</div>
                                    <div>{data.time}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </>
    )
}
