import Row1 from './row1/row1.component'
import Row2 from './row2/row2.component'
import Row3 from './row3/row3.component'

export default function WIP() {
    return <Dashboard />
}

export function Dashboard() {
    return (
        <div className="text-ellipsis grid lg:grid-cols-6 lg:grid-rows-12 lg:h-[calc(100vh-104px)] md:grid-cols-2 sm:grid-cols-12 overflow-hidden sm:overflow-y-scroll gap-1 ">
            <Row1 />
            <Row2 />
            <Row3 />
        </div>
    )
}
