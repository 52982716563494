/* eslint-disable no-unused-vars */
import { ITabItem } from 'components/tab/tab.interface'
import { useEffect, useState } from 'react'
import Details from './content/details.component'
import AddressList from './content/address-list.component'
import ContactList from './content/contact-list.component'
import Accounts from './content/accounts.component'
import EDocumentations from './content/e-documentations.component'
import {
    IAccounts,
    IAddressList,
    IContactList,
    IEDocumentations,
} from './organisation-details.interface'
import {
    OrganisationDataDummy,
    accountsDummy,
    addressListDummy,
    allContactDummy,
    contactListDummy,
    eDocumentationsDummy,
} from '../organisation.dummy'
import { IOrganisation } from '../organisation.interface'
import { useFormik } from 'formik'
import {
    accountInitialValue,
    accountValidation,
    addressListInitialValue,
    addressListValidation,
    contactListInitialValue,
    contactListValidation,
    orgDetailsInitialValue,
    orgDetailsValidation,
} from 'form-validation/limajari/organisation-details.validation'
import { Toast } from 'components/toast/toast.component'
import CreateOrEditAddressModal from './components/create-or-edit-address-modal.component'
import { useModal } from 'components/modal/modal.service'
import CreateOrEditContactModal from './components/create-or-edit-contact-modal.component'
import CreateOrEditAccountModal from './components/create-or-edit-account-modal.component'
import AddContactModal from './components/add-contact-modal.component'

const useOrganisationDetail = () => {
    // states
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'Details',
        key: 'status',
        value: 'details',
        className: 'gray',
    })
    const [orgDetails, setOrgDetails] = useState<IOrganisation>(
        OrganisationDataDummy.find((item) => item.code === 'ORG001')!,
    )
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isCreateAddress, setIsCreateAddress] = useState<boolean>(true)
    const [addressListData, setAddressListData] =
        useState<IAddressList[]>(addressListDummy)
    const [addressListDetails, setAddressListDetails] = useState<IAddressList>(
        addressListDummy[0],
    )
    const [contactListData, setContactListData] =
        useState<IContactList[]>(contactListDummy)
    const [allContact, setAllContact] =
        useState<IContactList[]>(allContactDummy)
    const [selectContact, setSelectContact] = useState<IContactList[]>([])
    const [contactListDetails, setContactListDetails] = useState<IContactList>(
        contactListDummy[0],
    )
    const [accountsData, setAccountsData] = useState<IAccounts[]>(accountsDummy)
    const [accountDetails, setAccountDetails] = useState<IAccounts>(
        accountsDummy[0],
    )
    const [eDocumentationsData, setEDocumentationsData] =
        useState<IEDocumentations[]>(eDocumentationsDummy)
    const [eDocumentationDetails, setEDocumentationDetails] =
        useState<IEDocumentations>(eDocumentationsDummy[0])

    // modal services
    const createOrEditModal = useModal()
    const removeModalService = useModal()
    const createOrEditContactModal = useModal()
    const removeContactModalService = useModal()
    const addContactModalService = useModal()
    const createOrEditAccountModal = useModal()
    const removeAccountModalService = useModal()

    // formik
    const formik = useFormik<IOrganisation>({
        validationSchema: orgDetailsValidation,
        initialValues: orgDetailsInitialValue,
        onSubmit: (values) => {
            submitOrgDetails(values)
        },
    })
    const addressListFormik = useFormik<IAddressList>({
        validationSchema: addressListValidation,
        initialValues: addressListInitialValue,
        onSubmit: (values) => {
            submitAddressList(values)
        },
    })
    const contactListFormik = useFormik<IContactList>({
        validationSchema: contactListValidation,
        initialValues: contactListInitialValue,
        onSubmit: (values) => {
            submitContactList(values)
        },
    })
    const accountFormik = useFormik<IAccounts>({
        validationSchema: accountValidation,
        initialValues: accountInitialValue,
        onSubmit: (values) => {
            submitAccount(values)
        },
    })

    // useEffect
    useEffect(() => {
        loadAddressListDetails()
    }, [addressListData])

    useEffect(() => {
        if (!isCreateAddress) {
            addressListFormik.setValues(addressListDetails)
            contactListFormik.setValues(contactListDetails)
            accountFormik.setValues(accountDetails)
        }
    }, [isCreateAddress])

    useEffect(() => {
        loadAllContact()
    }, [contactListData])

    useEffect(() => {
        searchAllContact()
    }, [])

    // functions
    const getContent = () => {
        const contens: Record<string, JSX.Element> = {
            details: Details({
                data: orgDetails,
                setData: (data) => setOrgDetails(data),
                isEdit: isEdit,
                setIsEdit: (data) => setIsEdit(data),
                formik: formik,
                modalService: removeModalService,
            }),
            addressList: AddressList({
                data: addressListData,
                detailsData: addressListDetails,
                setDataDetails: setDataDetails,
                func1: setDataAddressList,
                modalService: createOrEditModal,
                removeModalService: removeModalService,
                removeData: removeAddresList,
                setIsCreateAddress: (data: boolean) => setIsCreateAddress(data),
            }),
            contactList: ContactList({
                data: contactListData,
                detailsData: contactListDetails,
                setDetailsData: (data) => setContactListDetails(data),
                modalService: createOrEditContactModal,
                removeModalService: removeContactModalService,
                removeData: removeContactList,
                setIsCreateAddress: (data: boolean) => setIsCreateAddress(data),
                addContactModalService: addContactModalService,
            }),
            accounts: Accounts({
                data: accountsData,
                detailsData: accountDetails,
                setDetailsData: (data) => setAccountDetails(data),
                modalService: createOrEditAccountModal,
                removeModalService: removeAccountModalService,
                removeData: removeAccount,
                setIsCreateAddress: (data: boolean) => setIsCreateAddress(data),
            }),
            eDocumentations: EDocumentations({
                data: eDocumentationsData,
                detailsData: eDocumentationDetails,
                setDetailsData: (data) => setEDocumentationDetails(data),
            }),
        }
        return contens[tabFilter?.value] || <></>
    }

    // address List segment
    const setDataDetails = (data: IAddressList) => {
        setAddressListDetails(data)
    }
    const setDataAddressList = (id: number) => {
        const updateData = addressListData.map((item) => {
            if (item.id === id) {
                // Buat salinan item dan ubah mainAddress menjadi true
                return {
                    ...item,
                    mainAddress: !item.mainAddress,
                }
            }
            return item
        })
        setAddressListData(updateData)
    }
    const loadAddressListDetails = () => {
        const updateAddressData = addressListData.find(
            (item) => item.id === addressListDetails.id,
        )
        if (updateAddressData) setAddressListDetails(updateAddressData)
    }

    // segment organisation details
    const submitOrgDetails = (data: IOrganisation) => {
        setOrgDetails(data)
        setIsEdit(false)
        Toast({
            header: 'Organisation Updated',
            message: `Organisation:${data.fullName} successfully updated.`,
            type: 'success',
        })
    }

    // segment address list
    const submitAddressList = (data: IAddressList) => {
        if (isCreateAddress) {
            setAddressListData([...addressListData, data])
            Toast({
                header: 'New Address Created',
                message: `New Address:${data.addressName} successfully created.`,
                type: 'success',
            })
        } else {
            setAddressListData(
                addressListData.map((item) =>
                    item.id === data.id ? data : item,
                ),
            )
            Toast({
                header: 'Contact Updated',
                message: `Address:${data.addressName} successfully updated.`,
                type: 'success',
            })
        }
    }
    const removeAddresList = () => {
        const updateData = addressListData.filter(
            (address) => address.id !== addressListDetails.id,
        )
        setAddressListData(updateData)
        setAddressListDetails(updateData[0])
    }

    // segment contact list
    const submitContactList = (data: IContactList) => {
        if (isCreateAddress) {
            setContactListData([...contactListData, data])
            Toast({
                header: 'New Contact Created',
                message: `New Contact:${data.contactName} successfully created.`,
                type: 'success',
            })
        } else {
            setContactListData(
                contactListData.map((item) =>
                    item.id === data.id ? data : item,
                ),
            )
            Toast({
                header: 'Contact Updated',
                message: `Contact:${data.contactName} successfully updated.`,
                type: 'success',
            })
        }
    }
    const removeContactList = () => {
        const updateData = contactListData.filter(
            (item) => item.id !== contactListDetails.id,
        )
        setContactListData(updateData)
        setContactListDetails(updateData[0])
    }
    const loadAllContact = () => {
        const uniqueContacts = allContactDummy.filter(
            (allContact) =>
                !contactListData.some(
                    (contact) => contact.id === allContact.id,
                ),
        )
        setAllContact(uniqueContacts)
    }
    const searchAllContact = (contactName?: string) => {
        if (contactName) {
            const searchData = allContact.filter((contact) =>
                contact.contactName
                    .toLowerCase()
                    .includes(contactName.toLowerCase()),
            )
            searchData && setAllContact(searchData)
        } else {
            loadAllContact()
        }
    }
    const addContact = () => {
        if (selectContact?.length) {
            setContactListData([...contactListData, ...selectContact])
            Toast({
                header: 'Contact Updated',
                message: `${selectContact.length} contacts have been successfully updated`,
                type: 'success',
            })
        }
    }

    // segment account
    const submitAccount = (data: IAccounts) => {
        if (isCreateAddress) {
            setAccountsData([...accountsData, data])
            Toast({
                header: 'New Account Created',
                message: `New Account:${data.accountName} successfully created.`,
                type: 'success',
            })
        } else {
            setAccountsData(
                accountsData.map((item) => (item.id === data.id ? data : item)),
            )
            Toast({
                header: 'Account Updated',
                message: `Account:${data.accountName} successfully updated.`,
                type: 'success',
            })
        }
    }
    const removeAccount = () => {
        const updateData = accountsData.filter(
            (item) => item.id !== accountDetails.id,
        )
        setAccountsData(updateData)
        setAccountDetails(updateData[0])
    }

    // components
    const allModal = (
        <>
            <CreateOrEditAddressModal
                modalService={createOrEditModal}
                formik={addressListFormik}
                isCreate={isCreateAddress}
            />
            <CreateOrEditContactModal
                modalService={createOrEditContactModal}
                formik={contactListFormik}
                isCreate={isCreateAddress}
            />
            <CreateOrEditAccountModal
                modalService={createOrEditAccountModal}
                formik={accountFormik}
                isCreate={isCreateAddress}
            />
            <AddContactModal
                modalService={addContactModalService}
                loadData={searchAllContact}
                data={allContact}
                setSelectContact={(data) => setSelectContact(data)}
                addContact={addContact}
            />
        </>
    )

    return {
        tabFilter,
        isEdit,
        formik,
        orgDetails,
        allModal,
        setIsEdit,
        setTabFilter,
        getContent,
    }
}

export default useOrganisationDetail
