import Header from '../components/header.component'
import { useState } from 'react'
import Detail from '../detail.component'
import { totalShipmentMode, totalVolumeByMode } from './row2.dummy'
import FilterSection from '../components/filter-section.component'
import BarChartCustom from '../components/bar-chart.component'

function BarChartAnalytics() {
    const [isFilter, setIsFilter] = useState<boolean>(false)
    return (
        <div className="grid grid-rows-12 h-full">
            <Header
                className="h-5 row-span-1"
                title={`Total Shipments & Volume by Mode`}
                filter={`Last 7 Days (17/03/24 - 23/03/24)`}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
            />
            {isFilter ? (
                <FilterSection
                    className='grid gap-3 mt-6'
                    displayedInform={true}
                    filterBy={true}
                    dateRange={true}
                />
            ) : (
                <>
                    <Detail
                        className="row-span-3 grid-cols-6 h-14 self-center content-center"
                        data={totalVolumeByMode}
                    />
                    <BarChartCustom data={totalShipmentMode} tooltipTitle="Total Shipments" />
                </>
            )}

        </div>
    )
}

export default BarChartAnalytics
