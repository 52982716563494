import { FormikProvider } from "formik"
import useSetupSiteCreate from "../setup-site-create.service"
import Input from "components/input/input.component"

const DangerousGoodsDetails = () => {
    const {formikModule} = useSetupSiteCreate()
    return (
        <div className="flex">
            <FormikProvider value={formikModule}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        formikModule.handleSubmit()
                    }}
                    className="flex-col w-full p-4 space-y-4 h-[calc(100vh-284px)] overflow-auto"
                >
                    <div className="flex w-full gap-x-4">
                        <div className="flex-col w-1/4 h-full gap-y-[10px]">
                            <div className="flex">
                                <span className="flex text-size-M font-bold">
                                    DG Contact Info
                                </span>
                            </div>
                            <div className="flex">
                                <p className="flex text-size-S font-normal text-wrap">
                                    Input essential details for managing dangerous goods. Provide the contact name, address, and phone number for safe handling and compliance.
                                </p>
                            </div>
                        </div>
                        <div className="flex-col w-3/4 h-full space-y-4">
                            <div className="flex w-full gap-x-4">
                                <Input 
                                    label="DG CONTACT NAME"
                                    placeholder="Input DG Contact Name"
                                    required={false}
                                    type="text"
                                />
                                <Input 
                                    label="DG PHONE NUMBER"
                                    placeholder="Input DG Phone Number"
                                    required={false}
                                    type="text"
                                />
                            </div>
                            <div className="flex w-full gap-x-4">
                                <Input 
                                    label="DG ADDRESS LINE 1"
                                    placeholder="Add Address 1"
                                    required={false}
                                    type="text"
                                />
                            </div>
                            <div className="flex w-full gap-x-4">
                                <Input 
                                    label="DG ADDRESS LINE 2"
                                    placeholder="Add Address 2"
                                    required={false}
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </FormikProvider>
        </div>
    )
}

export default DangerousGoodsDetails