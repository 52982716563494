/* eslint-disable no-unused-vars */
import React, { ReactNode, useEffect, useState } from 'react'
import './tooltip.style.css'

const Tooltip = ({
    text,
    isShow,
    children,
    containerClassName = 'w-full',
}: {
    text: string
    isShow: boolean
    children: ReactNode
    containerClassName?: string
}) => {
    const [show, setShow] = useState(false)
    const clazz = show ? '' : 'hidden'

    useEffect(() => {
        setShow(isShow)
    }, [isShow])

    return (
        <>
            <div className={`${containerClassName}`}>
                {children}

                <div className={`${clazz} absolute z-[2] mt-1 `}>
                    <div className="arrow-up"></div>
                    <div
                        className={`themes-bg-gray-v8 themes-text-white bg-opacity-90 leading-none rounded p-3`}
                    >
                        {text}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tooltip
