import CardsDashboard from 'components/card-dashboard/card-dashboard.component'
import BarChartAnalytics from './bar-chart-analytics.component'
import DoughnutChart from './doughnut-chart.component'

export default function Row3() {
    return (
        <>
            {/* Bar Chart */}
            <CardsDashboard className="row-span-6 col-span-2 sm:col-span-12">
                <BarChartAnalytics />
            </CardsDashboard>

            {/* Doughnut Chart */}
            <CardsDashboard className="row-span-6 col-span-2 sm:col-span-12">
                <DoughnutChart />
            </CardsDashboard>
        </>
    )
}
