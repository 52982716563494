import FormDropdown from 'components/form-dropdown/form-dropdown.component'
import FormMeasurementInput from 'components/form-measurement-input/form-measurement-input.component'
import SimpleTable from 'components/simple-table/simple-table.component'
import { IServiceRate, IShippingRate } from '../new-quote.interface'
import { ISTColumn } from 'components/simple-table/simple-table.interface'

const ChargeList = ({
    shippingRateData,
    shippingRateHeaders,
    shippingRateFooter,
    serviceRateData,
    serviceRatetitle,
    serviceRateHeaders,
    serviceRateFooter,
    isDetailPage,
}: {
    shippingRateData: IShippingRate
    shippingRateHeaders: ISTColumn<IShippingRate>[]
    shippingRateFooter: JSX.Element
    serviceRateData: IServiceRate[]
    serviceRatetitle: JSX.Element
    serviceRateHeaders: ISTColumn<IServiceRate>[]
    serviceRateFooter: JSX.Element
    isDetailPage: boolean
}) => {
    return (
        <div className="flex flex-col gap-3">
            <div className="grid grid-cols-3 gap-3">
                <div className="border rounded ">
                    <div
                        className=" rounded-t themes-bg-gray-v5 themes-text-white flex justify-center"
                        style={{ backgroundColor: 'var(--loglines-gray-v5)' }}
                    >
                        Currency
                    </div>
                    <div className="p-3 themes-bg-white rounded-b">
                        <FormDropdown
                            options={[
                                {
                                    label: 'IDR - INDONESIAN RUPIAH',
                                    value: 'IDR - INDONESIAN RUPIAH',
                                },
                            ]}
                            dropDownIndicator={true}
                            isClearable={true}
                            placeholder="Select currency"
                            name="currency"
                            parentDivClassName="!mb-0 col-span-3"
                            disabled={isDetailPage}
                        />
                    </div>
                </div>
                <div className="border rounded">
                    <div
                        className="rounded-t themes-text-white flex justify-center"
                        style={{ backgroundColor: 'var(--loglines-gray-v5)' }}
                    >
                        Weight
                    </div>
                    <div className="p-3 themes-bg-white rounded-b">
                        <FormMeasurementInput
                            options={[
                                {
                                    label: 'Kilogram',
                                    value: 'KG',
                                },
                            ]}
                            defaultOption={{
                                label: 'Kilogram',
                                value: 'KG',
                            }}
                            name={'weight'}
                            placeholder="Enter here"
                            disabled={isDetailPage}
                        />
                    </div>
                </div>
                <div className="border rounded ">
                    <div
                        className=" rounded-t themes-bg-gray-v5 themes-text-white flex justify-center"
                        style={{ backgroundColor: 'var(--loglines-gray-v5)' }}
                    >
                        Volume Weight
                    </div>
                    <div className="p-3 themes-bg-white rounded-b">
                        <FormMeasurementInput
                            options={[
                                {
                                    label: 'Cubic Meter',
                                    value: 'CBM',
                                },
                            ]}
                            defaultOption={{
                                label: 'Cubic Meter',
                                value: 'CBM',
                            }}
                            name={'volume'}
                            placeholder="Enter here"
                            disabled={isDetailPage}
                        />
                    </div>
                </div>
            </div>
            {/* done for table */}
            <div className="h-auto flex flex-col">
                <SimpleTable<IShippingRate>
                    headers={shippingRateHeaders}
                    data={[shippingRateData]}
                    title={<>SHIPPING RATES</>}
                    footer={shippingRateFooter}
                />
            </div>
            <div className="themes-bg-white border h-[100vh-40rem)]">
                <SimpleTable<IServiceRate>
                    headers={serviceRateHeaders}
                    data={serviceRateData}
                    title={serviceRatetitle}
                    footer={serviceRateFooter}
                />
            </div>
        </div>
    )
}

export default ChargeList
