import Button from 'components/button/button.component'
import Modal from 'components/modal/modal.component'
import { IUseModal } from 'components/modal/modal.service'

const DuplicateQouteModal = ({
    modalService,
    onSubmit,
}: {
    modalService: IUseModal
    onSubmit: () => void
}) => {
    return (
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3 !p-0">
            <div>
                <div className="p-3 font-bold text-size-L">
                    DUPLICATE & CREATE NEW QUOTE FROM QRN000002?
                </div>
                <div className="border-b"></div>
                <div className="p-3">
                    Are you sure you want to create new quote with existing data
                    from <span className="font-bold">QRN000002</span>?
                </div>
                <div className="border-b"></div>
                <div className="flex gap-3 p-3">
                    <Button
                        onClick={() => modalService.closeModalHandling()}
                        label="BACK"
                        className="w-1/2"
                    />
                    <Button
                        onClick={() => onSubmit()}
                        label="YES"
                        variant="brand"
                        className="w-1/2"
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DuplicateQouteModal
