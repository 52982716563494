import Dropdown from 'components/dropdown/dropdown.component'
import { typographySize } from './settings.dummy'
import Toggle from 'components/toggle/toggle.component'
import { languageDropdown } from './settings.static'
import { useThemes } from "./settings.services"


export default function Settings() {
    const borderComponent = (
        <div className={`border-b`}></div>
    )
    const { themes, timezonesList, selectedThemes, themesChangesHandling } = useThemes()

    return (
        <div className="flex flex-col rounded-lg shadow-md themes-bg-white container-global content-full-height">
            <div className='overflow-auto'>
                {/* Visual Preferences */}
                <div className="p-4">Visual Preferences</div>
                {borderComponent}
                <div className='flex flex-col gap-y-2 py-4'>
                    <div className='flex w-[70%] gap-x-4 px-4'>
                        <div className='w-[35%]'>
                            <Toggle lableEnable={'LIGHT'} lableDisable={'DARK'} />
                        </div>
                        <div className='w-full'>
                            <div className="text-size-S">Display Modes</div>
                            <div className="text-size-XS leading-none">
                                Change your preferred language in your portal,
                                every text in the portal will be changed into
                                the selected language.
                            </div>
                        </div>
                    </div>
                    <div className='flex w-[70%] gap-x-4 px-4'>
                        <div className='w-[35%]'>
                            <Dropdown
                                options={typographySize}
                                defaultValue={[typographySize[1]]}
                                dropDownIndicator
                            />
                        </div>
                        <div className='w-full'>
                            <div className="text-size-S">Typography Size</div>
                            <div className="text-size-XS leading-none">
                                Adjust your prefered timezones, this will modify all of the time and dates in the portal.
                            </div>
                        </div>
                    </div>
                    <div className='flex w-[70%] gap-x-4 px-4'>
                        <div className='w-[35%]'>
                            <Dropdown
                                options={themes}
                                placeholder="Select Available Reports"
                                dropDownIndicator={true}
                                value={selectedThemes}
                                onClick={(val) => {
                                    if (!val) return
                                    themesChangesHandling(val)
                                }}
                            />
                        </div>
                        <div className='w-full'>
                            <div className="text-size-S">Display Modes</div>
                            <div className="text-size-XS leading-none">
                                Adjust your prefered timezones, this will modify all of the time and dates in the portal.
                            </div>
                        </div>
                    </div>
                </div>

                {/* Portal Preferences */}
                <div className="p-4">Portal Preferences</div>
                {borderComponent}
                <div className='flex flex-col gap-y-2 pt-4'>
                    <div className='flex w-[70%] gap-x-4 px-4'>
                        <div className='w-[35%]'>
                            <Dropdown
                                options={languageDropdown}
                                defaultValue={[languageDropdown[0]]}
                                dropDownIndicator
                            />
                        </div>
                        <div className='w-full'>
                            <div className="text-size-S">Language Selection</div>
                            <div className="text-size-XS leading-none">
                                Change your preferred language in your portal,
                                every text in the portal will be changed into
                                the selected language.
                            </div>
                        </div>
                    </div>
                    <div className='flex w-[70%] gap-x-4 px-4'>
                        <div className='w-[35%]'>
                            <Dropdown
                                options={timezonesList}
                                defaultValue={[timezonesList[0]]}
                                dropDownIndicator
                            />
                        </div>
                        <div className='w-full'>
                            <div className="text-size-S">Timezone Selection</div>
                            <div className="text-size-XS leading-none">
                                Adjust your prefered timezones, this will modify all of the time and dates in the portal.
                            </div>
                        </div>
                    </div>
                    <div className='flex w-[70%] gap-x-4 px-4'>
                        <div className='w-[35%]'>
                            <Toggle lableEnable={'ENABLE'} lableDisable={'DISABLE'} />
                        </div>
                        <div className='w-full'>
                            <div className="text-size-S">Notification</div>
                            <div className="text-size-XS leading-none">
                                Apply notification in your browser to allow the portal to send any available notification that automatically triggers.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
