import { IDropdownItem } from 'components/dropdown/dropdown.interface'
import { useModal } from 'components/modal/modal.service'
import { Toast } from 'components/toast/toast.component'
import {
    IFUser,
    userFormInitial,
    userFormUpdate,
    userFormValidation,
} from 'form-validation/common/user.validation'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getRegion } from 'repository/common/common.repository'

export interface IUseUserAccessForm {
    isNew: boolean
    isProfilePage: boolean
}
export const useUserAccessForm = ({ isNew }: IUseUserAccessForm) => {
    const nav = useNavigate()
    const [province, setProvince] = useState<IDropdownItem[]>([])
    const [city, setCity] = useState<IDropdownItem[]>([])
    const moduleModalService = useModal()

    const formikModule = useFormik<IFUser>({
        validationSchema: userFormValidation,
        initialValues: isNew ? userFormInitial : userFormUpdate,
        onSubmit: (values) => {
            const fullname = values.fullName
            const title = isNew
                ? `User "${fullname}" Created`
                : `User "${fullname}" Updated`
            const message = isNew
                ? `"${fullname}" has been successfully Created.`
                : `"${fullname}" has been successfully Updated.`
            Toast({
                header: title,
                message: message,
                type: 'success',
            })
            nav('/manage-users')
        },
    })

    const getProvinceDropdown = async () => {
        const region = getRegion()
        const dropdown: IDropdownItem[] = region.map((d) => {
            return { label: d.province, value: d.province } as IDropdownItem
        })
        setProvince(dropdown)
    }

    const getCityDropdown = async (selectedProvince: string) => {
        const region = getRegion()
        const province = region.find((d) => d.province === selectedProvince)
        if (!province) {
            setCity([])
            return
        }

        const dropdown = province.city.map((d) => {
            return { label: d, value: d } as IDropdownItem
        })
        setCity(dropdown)
    }

    // Return ----------------------------------------------------------------------
    return {
        formikModule,
        province,
        city,
        moduleModalService,
        nav,
        getCityDropdown,
        getProvinceDropdown,
    }
}
