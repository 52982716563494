import SimpleTable from 'components/simple-table/simple-table.component'
import { PackLinesHeader } from '../create-booking.static'

const GoodsDetails = ({ actionHandling, attachOrder }: { actionHandling: () => void, attachOrder: any[] }) => {
    return (
        <div className="flex flex-col w-full p-4 space-y-4 h-[calc(100vh-280px)] overflow-y-auto">

            <div className="flex-col w-full space-y-4">
                <div className="flex w-full gap-4">
                    <SimpleTable
                        headers={PackLinesHeader}
                        data={attachOrder}
                        addRowClick={() => actionHandling()}
                        isAddRow={true}
                        addRowLabel="Add Orders"
                        useNumber={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default GoodsDetails
