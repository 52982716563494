import { IMeta } from 'common/common.interface'
import { IShipments } from './shipments.interface'

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}

export const ShipmentDataDummy: IShipments[] = [
    {
        shipmentID: 'S00001041',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '03-05-2024',
        originPort: 'THBKK - Bangkok',
        destinationPort: 'AUMEL - Melbourne',
        packs: '100',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'booked',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001042',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '15-06-2024',
        originPort: 'USNYC - New York',
        destinationPort: 'AUMEL - Melbourne',
        packs: '0',
        weightMeasure: '2000 KG',
        volumeMeasure: '200 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'booked',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001043',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '21-07-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'waitListed',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001044',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '09-08-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'waitListed',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001045',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '28-09-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'confirmed',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001046',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '10-10-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'confirmed',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001047',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '05-11-2024',
        originPort: 'IDJKT - Jakarta, Java',
        destinationPort: 'AUMEL - Melbourne',
        packs: '0',
        weightMeasure: '2000 KG',
        volumeMeasure: '200 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'booked',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001048',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '17-12-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'waitListed',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001049',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '22-01-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'confirmed',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001050',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '14-02-2024',
        originPort: 'IDJKT - Jakarta, Java',
        destinationPort: 'AUMEL - Melbourne',
        packs: '0',
        weightMeasure: '2000 KG',
        volumeMeasure: '200 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'others',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001051',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '26-03-2024',
        originPort: 'THBKK - Bangkok',
        destinationPort: 'AUMEL - Melbourne',
        packs: '22',
        weightMeasure: '90 KG',
        volumeMeasure: '90 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'others',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001052',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '08-04-2024',
        originPort: 'THBKK - Bangkok',
        destinationPort: 'AUMEL - Melbourne',
        packs: '22',
        weightMeasure: '90 KG',
        volumeMeasure: '90 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'others',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001053',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '19-05-2024',
        originPort: 'THBKK - Bangkok',
        destinationPort: 'AUMEL - Melbourne',
        packs: '22',
        weightMeasure: '90 KG',
        volumeMeasure: '90 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'others',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001054',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '14-02-2024',
        originPort: 'IDJKT - Jakarta, Java',
        destinationPort: 'AUMEL - Melbourne',
        packs: '0',
        weightMeasure: '2000 KG',
        volumeMeasure: '200 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'others',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
    {
        shipmentID: 'S00001055',
        goodsDescription: 'Seamless carbon steel pipes for industrial use',
        shipperReference: '10-10-2024',
        originPort: 'USLAX - Los Angeles',
        destinationPort: 'AUMEL - Melbourne',
        packs: '20',
        weightMeasure: '1000 KG',
        volumeMeasure: '10 M3',
        masterBill: '0232132152AA23',
        loadPort: 'SGSIN - Singapore',
        status: 'others',
        address: {
            shipperCompany: '',
            shipperStreet: '',
            shipperCity: '',
            shipperState: '',
            shipperPostalCode: '',
            shipperCountry: '',
            shipperContact: '',
            shipperPhone: '',
            shiperMobile: '',
            shipperEmail: '',
            consigneeCompany: '',
            consigneeStreet: '',
            consigneeCity: '',
            consigneeState: '',
            consigneePostalCode: '',
            consigneeCountry: '',
            consigneeContact: '',
            consigneePhone: '',
            consigneeMobile: '',
            consigneeEmail: '',
            originPort: '',
            originPCRequestedDate: '',
            originPickupEquipmentNeeded: '',
            destinationPort: '',
            destinationDLVRequestedDate: '',
            destinationDeliveryEquipmentNeeded: '',
        },
        serviceLevel: '',
        incoterm: '',
        additionalTerm: '',
        markAndNumber: '',
        goodsValue: '',
        goodsValueCurrency: '',
        insuranceValue: '',
        insuranceValueCurrency: '',
        packLines: [
            {
                packs: '',
                packageType: '',
                lengthMeasure: '',
                widthMeasure: '',
                heightMeasure: '',
                weightMeasure: '',
                volumeMeasure: '',
                goodsDescription: '',
            },
        ],
        outerPacksUnit: '',
        volumeUOM: '',
        weightUOM: '',
        documents: [
            {
                actions: '',
                filename: '',
                description: '',
                added: '',
            },
        ],
    },
]
