/* eslint-disable no-unused-vars */
import TabMaster from 'components/tab-master/tab-master.component'
import useQuotes from './quotes.service'
import { metaDummy } from './quotes.static'
import { IQuote } from './quotes.interface'
import Table from 'components/table/table.component'

const Quotes = () => {
    const {
        setTabFilter,
        handleSearch,
        navigate,
        tabItems,
        rowOptionComponent,
        quoteHeaders,
        quotessDataByStatus,
        tabFilter,
    } = useQuotes()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    items={tabItems || []}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Quotation No. (min 3 char)'}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    // iconButton1={{
                    //     onClick: () =>
                    //         filterOverlayService.toggleOverlayHandling(),
                    //     icon: 'ri-filter-2-line',
                    //     filterOverlayComponent: (
                    //         <QuotationFilterOverlay
                    //             filterOverlayService={filterOverlayService}
                    //         />
                    //     ),
                    // }}
                    onSearchSubmit={(values) => handleSearch(values)}
                    iconButton2={{
                        onClick: () => {},
                        icon: 'ri-settings-2-line',
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate('/new-quote')
                        },
                        label: 'NEW QUOTE',
                        className: '!text-size-S',
                    }}
                />

                <Table<IQuote>
                    headers={
                        tabFilter.value === 'allQuotes'
                            ? quoteHeaders
                            : quoteHeaders.filter(
                                  (item) => item.accessor !== 'mode',
                              )
                    }
                    data={quotessDataByStatus}
                    loading={false}
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    moduleTitle={'Shipment'}
                    onRowClick={function (): void {
                        navigate('/quote-details/')
                    }}
                    rowOptionComponent={rowOptionComponent}
                />
            </div>
        </div>
    )
}

export default Quotes
