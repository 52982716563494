import { IResponseData } from 'common/common.interface'
import { fetch } from 'common/common.service'
import { endpoints } from 'common/endpoints'
import { IDropdownItem } from 'components/dropdown/dropdown.interface'
import { ICountry, IRegion } from './interface/country.interface'
import json from './static-data/dummy-country.json'
import jsonRegion from './static-data/dummy-region.json'

export const getCountry = async () => {
    try {
        const response = await fetch<any, any>({
            endpoint: endpoints.common.country,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<any>
        err.isSuccess = false
        return err
    }
}

export const getOrganization = async () => {
    try {
        const response = await fetch<any, any>({
            endpoint: endpoints.common.organization,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<any>
        err.isSuccess = false
        return err
    }
}

export const getCountryDropdown = (): IDropdownItem<ICountry>[] => {
    const countryJson = json as unknown[] as ICountry[]
    const countryDropdown: IDropdownItem<ICountry>[] = countryJson.map(
        (data: ICountry) => {
            const dropdown: IDropdownItem<ICountry> = {
                label: data.alpha2 + ' - ' + data.name,
                value: data.alpha2,
            }
            return dropdown
        },
    )
    return countryDropdown
}

export const getRegion = (): IRegion[] => {
    const countryJson = jsonRegion as unknown[] as IRegion[]
    return countryJson
}
