import { useEffect, useRef, useState } from 'react'

export interface IUseOverlay {
    isOverlayOpen: boolean
    openOverlayHandling: () => void
    closeOverlayHandling: () => void
    toggleOverlayHandling: () => void
    overlayRef: React.RefObject<HTMLDivElement>
}

const useOverlay = (): IUseOverlay => {
    const [isOverlayOpen, setOverlayOpen] = useState<boolean>(false)
    const overlayRef = useRef<HTMLDivElement>(null)

    const openOverlayHandling = () => setOverlayOpen(true)
    const closeOverlayHandling = () => setOverlayOpen(false)
    const toggleOverlayHandling = () => setOverlayOpen(!isOverlayOpen)

    useEffect(() => {
        // handle event listener
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('keydown', handleEscapeKey)
    }, [])

    const handleClickOutside = (event: MouseEvent) => {
        if (
            overlayRef?.current &&
            !overlayRef?.current?.contains(event?.target as HTMLDivElement)
        ) {
            closeOverlayHandling()
        }
    }

    const handleEscapeKey = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            closeOverlayHandling()
        }
    }

    return {
        openOverlayHandling,
        closeOverlayHandling,
        toggleOverlayHandling,
        isOverlayOpen,
        overlayRef,
    }
}

export default useOverlay
