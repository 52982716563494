import SimpleTable from 'components/simple-table/simple-table.component'
import { relatedInvoiceHeader } from '../shipments-details.static'

const RelatedInvoice = () => {
    const data = [
        {
            invoiceNo: "JPL000001508",
            issuer: "FIA",
            type: "INV",
            terms: "Charges Include Carton Packaging",
            invDate: "06-Apr-2023",
            dueDate: "06-Apr-2023",
            currency: "IDR",
            amount: "1.405.750",
            outstandingAmount: "0",
            paidDate: "18-Apr-2023",
        },
        {
            invoiceNo: "JPL000001509",
            issuer: "FIA",
            type: "INV",
            terms: "Charges Include Carton Packaging",
            invDate: "06-Apr-2023",
            dueDate: "06-Apr-2023",
            currency: "IDR",
            amount: "1.405.750",
            outstandingAmount: "0",
            paidDate: "18-Apr-2023",
        }
    ]
    return (
        <SimpleTable<any>
            headers={relatedInvoiceHeader}
            data={data}
        />
    )
}

export default RelatedInvoice
