import { faker } from '@faker-js/faker'

export const paymentInvoiceCompletion = [
    {
        name: 'Mon',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'Tue',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'Wed',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'Thu',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'Fri',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'Sat',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'Now',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
]

export const shipmentOrderAnalytics = [
    {
        name: 'Jan',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Feb',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Mar',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Apr',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'May',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Jun',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Jul',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Aug',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Sep',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Oct',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Nov',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Dec',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
]

export const paymentInvoiceDetail = [
    {
        mode: 'Avg. per month',
        detail: 'US$ 38,585',
    },
    {
        mode: 'Total costs overtime',
        detail: 'US$ 100,585',
    },
]

export const openInvoice = [
    {
        name: '1 Within payment term',
        value: 18906,
        percentage: 33.15,
        fill: '#CDF7EC',
        textColor: '#048160',
    },
    {
        name: '1 week overdue',
        value: 8806,
        percentage: 24.49,
        fill: '#FFF6E1',
        textColor: '#997D3E',
    },
    {
        name: '>1 week overdue',
        value: 4643,
        percentage: 17.15,
        fill: '#FCDAE2',
        textColor: '#902B43',
    },
]
