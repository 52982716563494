import { ITabItem } from 'components/tab/tab.interface'
import { ContainerDataDummy } from './containers.dummy'
import { ITableColumn } from 'components/table/table.interface'

export const ContainerStatusHeader: ITabItem[] = [
    {
        label: 'All Containers',
        totalData: ContainerDataDummy.length,
        key: 'status',
        value: 'allContainers',
        className: 'brand',
    },
    {
        label: 'SEA',
        totalData: ContainerDataDummy.filter(
            (x: any) => x.typeTransport === 'SEA',
        ).length,
        key: 'typeTransport',
        value: 'SEA',
        className: 'green',
    },
    {
        label: 'AIR',
        totalData: ContainerDataDummy.filter(
            (x: any) => x.typeTransport === 'AIR',
        ).length,
        key: 'status',
        value: 'AIR',
        className: 'yellow',
    },
    {
        label: 'LAND',
        totalData: ContainerDataDummy.filter(
            (x: any) => x.typeTransport === 'LAND',
        ).length,
        key: 'status',
        value: 'LAND',
        className: 'red',
    },
]

export const ContainerSummaryDetailHeader: ITableColumn[] = [
    {
        label: 'Container Type',
        accessor: 'containerType',
    },
    {
        label: 'Container Mode',
        accessor: 'containerMode',
    },
    {
        label: 'Shipment ID',
        accessor: 'shipmentID',
    },
    {
        label: 'Weight',
        accessor: 'weight',
    },
    {
        label: 'Carrier',
        accessor: 'carrier',
    },
    {
        label: 'Vessel',
        accessor: 'vessel',
    },
    {
        label: 'Voyage / Flight # / Truck Ref',
        accessor: 'typeTransportCode',
    },
    {
        label: 'Gross Weight Verification Method',
        accessor: 'method',
    },
    {
        label: 'Last Edit Time',
        accessor: 'lastEdit',
    },
]
