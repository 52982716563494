/* eslint-disable no-unused-vars */
import {
  headerPillsShippingDetails,
  pillSAddressesDetailsHeader,
} from "../shipments-details.static";
import Pills from "components/pills/pills.component";

const Details = ({
  setPillShippingDetails,
  setPillAddressesDetails,
  getContentPillShippingDetails,
  getContentPillAddressesDetails,
}: any) => {
  return (
    <div className="flex gap-4 sm:flex-col sm:overflow-auto h-full">
      <div className="border border-solid border-logistical-gray-ver3 rounded flex-1 overflow-hidden z-[1]">
        <div className="p-3 font-bold text-size-L">Shipping Details</div>
        <div className="border-b border-solid border-logistical-gray-ver3"></div>
        <Pills
          items={headerPillsShippingDetails}
          onChange={(e: any) => {
            setPillShippingDetails(e);
          }}
        />
        <div className="overflow-auto h-[calc(100vh-450px)] sm:h-full pl-3">
          {getContentPillShippingDetails}
        </div>
      </div>
      <div className="border border-solid border-logistical-gray-ver3 rounded flex-1">
        <div className="p-3 font-bold text-size-L">Addresses Details</div>
        <div className="border-b border-solid border-logistical-gray-ver3"></div>
        <Pills
          items={pillSAddressesDetailsHeader}
          onChange={(e: any) => {
            setPillAddressesDetails(e);
          }}
        />
        <div
          className="overflow-auto h-[calc(100vh-450px)] sm:h-full pl-3 flex flex-col gap-y-2"
          style={{ lineHeight: "normal" }}
        >
          {getContentPillAddressesDetails}
        </div>
      </div>
    </div>
  );
};

export default Details;
