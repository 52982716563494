import Button from 'components/button/button.component'
import Tab from 'components/tab/tab.component'
import { ContentSetupSiteForm } from './setup-site-form.static'
import useSetupSiteForm from './setup-site-form.service'
import { Toast } from 'components/toast/toast.component'

const SetupSiteForm = () => {
    const { navigate, tabFilter, setTabFilter, getContentDetailSite, id } =
        useSetupSiteForm()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <div className="flex w-full justify-between items-center p-4">
                    <div className="flex">
                        <span className="flex text-size-L font-bold items-center">
                            SITE DETAILS
                        </span>
                    </div>
                    <div className="flex space-x-2">
                        <Button
                            label="RESET CHANGES"
                            onClick={() => {}}
                            variant="default"
                            className="w-btnRegularWidth"
                        />
                        <Button
                            label="SAVE CHANGES"
                            onClick={() => {
                                Toast({
                                    header: 'SITE DETAILS SAVED',
                                    message: `Site ${id} information is updated.`,
                                    type: 'success',
                                })
                            }}
                            variant="brand"
                            className="w-btnRegularWidth"
                        />
                    </div>
                </div>
                <div className="border-b border-logistical-gray-ver7" />
                <Tab
                    containerClassName="px-3 pt-3"
                    items={ContentSetupSiteForm ?? []}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                />
                <div className="border-b border-logistical-gray-ver7" />
                {getContentDetailSite(tabFilter)}
                <div className="border-b border-logistical-gray-ver7" />
                <div className="flex w-full items-center p-4 justify-start">
                    <div className="flex gap-4">
                        <Button
                            className="w-btnRegularWidth"
                            label={'BACK'}
                            onClick={() => {
                                navigate('/site')
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetupSiteForm
