/* eslint-disable no-unused-vars */
import SimpleTable from 'components/simple-table/simple-table.component'
import { IAddressList } from '../organisation-details.interface'
import { addressListHeaders } from '../organisation-details.static'
import Input from 'components/input/input.component'
import Checkbox from 'components/checkbox/checkbox.component'
import ToggleSwitch from 'components/toggle-switch/toggle-switch.component'
import Textarea from 'components/text-area/text-area.component'
import { IUseModal } from 'components/modal/modal.service'
import RemoveModalAddress from '../components/remove-address-modal.component'

const AddressList = ({
    data,
    detailsData,
    setDataDetails,
    func1,
    modalService,
    removeModalService,
    removeData,
    setIsCreateAddress,
}: {
    data: IAddressList[]
    detailsData: IAddressList
    func1: (id: number, mainAddress: boolean) => void
    setDataDetails: (data: IAddressList) => void
    modalService: IUseModal
    removeModalService: IUseModal
    removeData: () => void
    setIsCreateAddress: (data: boolean) => void
}) => {
    return (
        <div className="flex gap-x-2">
            <div className="w-3/4 h-[calc(100vh-15.5rem)]">
                <SimpleTable<IAddressList>
                    headers={addressListHeaders}
                    data={data}
                    isAddRow={true}
                    addRowLabel="Add New Address"
                    onRowClick={(data) => setDataDetails(data)}
                    customBuildParam4={() => ({
                        // set details
                        func1: () => func1(detailsData.id, true),
                        func2: () => {
                            setIsCreateAddress(false)
                            modalService.openModalHandling()
                        },
                        func3: () => {
                            removeModalService.openModalHandling()
                        },
                    })}
                    addRowClick={() => {
                        setIsCreateAddress(true)
                        modalService.openModalHandling()
                    }}
                    variant="gray"
                />
            </div>
            <div className="w-1/4 border rounded h-[calc(100vh-15.5rem)] flex flex-col">
                <div className="p-4">Address Details</div>

                {/* border */}
                <div className="border-b"></div>
                {/* border */}

                <div className="p-4 overflow-auto">
                    <div>
                        <ToggleSwitch
                            initialOn={detailsData.mainAddress}
                            label="Main Address"
                            onClick={() => {
                                func1(detailsData.id, true)
                            }}
                        />
                    </div>
                    <div className="grid grid-cols-2 grid-rows-2 gap-3">
                        <Input
                            label="Address Name"
                            disabled={true}
                            parentInputClassName="col-span-2"
                            value={detailsData.addressName}
                        />
                        <Textarea
                            parentInputClassName="col-span-2 row-span-2"
                            label="Address 1"
                            disabled={true}
                            value={detailsData.address1}
                        />
                        <Textarea
                            parentInputClassName="col-span-2 row-span-2"
                            label="Address 2 (Optional)"
                            disabled={true}
                        />
                        <Input
                            label="Country/Region"
                            disabled={true}
                            value={detailsData.countryOrRegion}
                        />
                        <Input
                            label="State"
                            disabled={true}
                            value={detailsData.state}
                        />
                        <Input
                            label="City"
                            disabled={true}
                            value={detailsData.city}
                        />
                        <Input
                            label="Postcode"
                            disabled={true}
                            value={detailsData.postcode}
                        />
                        <Input
                            label="Unloco"
                            disabled={true}
                            parentInputClassName="col-span-2"
                            value={detailsData.unloco}
                        />
                        <Input
                            label="Related City/Port"
                            disabled={true}
                            parentInputClassName="col-span-2"
                        />
                        <div className="col-span-2">
                            <ToggleSwitch
                                label="Acitve Address"
                                disabled={true}
                                initialOn={detailsData.activeAddress}
                            />
                        </div>
                        <div className="col-span-2 border-b"></div>
                        <div className="col-span-2">Address Capabilities</div>
                        <div className="col-span-2 themes-bg-gray-v2">
                            <div className="flex flex-col gap-y-3 p-3 ">
                                <div className="flex gap-3">
                                    <Checkbox onChecked={() => {}} />
                                    <div className="leading-none">
                                        Accounts Payable Mailing Address
                                    </div>
                                </div>
                                <div className="flex gap-3">
                                    <Checkbox onChecked={() => {}} />
                                    <div className="leading-none">
                                        Accounts Receivable Mailing Address
                                    </div>
                                </div>
                                <div className="flex gap-3">
                                    <Checkbox onChecked={() => {}} />
                                    <div className="leading-none">
                                        AWB Address
                                    </div>
                                </div>
                                <div className="flex gap-3">
                                    <Checkbox onChecked={() => {}} />
                                    <div className="leading-none">
                                        Customs Address of Record
                                    </div>
                                </div>
                                <div className="flex gap-3">
                                    <Checkbox onChecked={() => {}} />
                                    <div className="leading-none">
                                        Consignment Delivery Address
                                    </div>
                                </div>
                                <div className="flex gap-3">
                                    <Checkbox onChecked={() => {}} />
                                    <div className="leading-none">
                                        EU Customs Address
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RemoveModalAddress
                modalService={removeModalService}
                type="removeAddress"
                onClick={() => {
                    removeData()
                }}
                data={detailsData.addressName}
            />
        </div>
    )
}

export default AddressList
