import Button from 'components/button/button.component'
import FormInput from 'components/form-input/form-input.component'
import FormTextArea from 'components/form-textarea/form-textarea.component'
import Modal from 'components/modal/modal.component'
import { IUseModal } from 'components/modal/modal.service'
import { FormikContextType, FormikProvider } from 'formik'

const CreateOrEditAccountModal = ({
    modalService,
    formik,
    isCreate,
}: {
    modalService: IUseModal
    formik: FormikContextType<any>
    isCreate: boolean
}) => {
    return (
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3  px-0">
            <FormikProvider value={formik}>
                <div>
                    <div className="flex justify-between px-3">
                        <div className="flex items-center font-bold text-size-L">
                            {isCreate ? 'CREATE NEW ACCOUNT' : 'EDIT ACCOUNT'}
                        </div>
                        <Button
                            onClick={() => modalService.closeModalHandling()}
                            icon="ri-close-fill"
                            className="border-none w-btnIconWidth"
                        />
                    </div>
                    <div className="border-b"></div>
                    <div className="flex flex-col p-3 gap-3">
                        <FormInput
                            label="Account Name"
                            name="accountName"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Account Name"
                        />
                        <FormInput
                            label="Account Number"
                            name="accountNumber"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Account Number"
                        />
                        <FormInput
                            label="Job Category"
                            name="jobCategory"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Job Category"
                        />
                        <FormInput
                            label="Routing Number"
                            name="routingNumber"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Routing Number"
                        />
                        <FormInput
                            label="Currency"
                            name="currency"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Currency"
                        />
                        <FormTextArea
                            label="Remark (Optional)"
                            name="remark"
                            placeholder="Enter Remark"
                        />
                    </div>
                    <div className="border-b"></div>
                    <div className="px-3 mt-3">
                        <Button
                            onClick={() => {
                                modalService.closeModalHandling()
                                formik.submitForm()
                            }}
                            label={
                                isCreate ? 'CREATE ACCOUNT' : 'UPDATE ACCOUNT'
                            }
                            variant="brand"
                            className="w-full"
                        />
                    </div>
                </div>
            </FormikProvider>
        </Modal>
    )
}

export default CreateOrEditAccountModal
