import TabMaster from 'components/tab-master/tab-master.component'
import Table from 'components/table/table.component'
import SetupWarehouseFilterOverlay from './components/setup-site-filter-overlay.component'
import { HeaderStatusData, SetupSiteSummaryHeader } from './setup-site.static'
import { SetupSiteDataDummy } from './setup-site.dummy'
import useSetupSite from './setup-site.service'
import SetupSiteFilter from './components/setup-site-filter.component'
import { ISite } from 'form-validation/poc/site.validation'

const SetupSite = () => {
    const {
        data,
        tabFilter,
        meta,
        filterOverlayService,
        generateReportModalService,
        navigate,
        setStatus,
    } = useSetupSite()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    useSearch={true}
                    placeHolderSearch="Search (minimum 3 char)"
                    items={HeaderStatusData}
                    onChange={(item) => setStatus(item)}
                    tabFilter={tabFilter}
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <SetupWarehouseFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate('/site-create/')
                        },
                        label: 'CREATE SITE',
                        className: '!text-size-S',
                    }}
                />
                <Table<ISite>
                    headers={SetupSiteSummaryHeader}
                    data={data}
                    loading={false}
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit prev API -> ' + page)
                    }}
                    meta={meta}
                    moduleTitle={'Booking'}
                    onRowClick={function (data): void {
                        const id = data.siteID
                        navigate('/site-details/' + id)
                    }}
                    checkboxDataHandling={() => {
                        console.log('Checked')
                    }}
                    tabFilterItem={tabFilter}
                    enableExport={true}
                    containerClassname={'overflow-auto'}
                    modalService={generateReportModalService}
                    components={<SetupSiteFilter />}
                    resetFilter={() => {}}
                    getDataGenerate={() => {}}
                    generateReportData={SetupSiteDataDummy}
                    GenerateReportHeaders={SetupSiteSummaryHeader}
                />
            </div>
        </div>
    )
}

export default SetupSite
