import { ITabItem } from 'components/tab/tab.interface'
import { IQuote, IQuotesStatusTotal } from './quotes.interface'
import { quotesDummy } from './quotes.dummy'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'

interface IQuotesSlice {
    data: IQuote[]
    dataByStatus: IQuote[]
    statusTotal: IQuotesStatusTotal
    statusSelected: ITabItem
}
export const initialState: IQuotesSlice = {
    data: quotesDummy,
    dataByStatus: [],
    statusTotal: {
        allQuotes: 0,
        sea: 0,
        air: 0,
    },
    statusSelected: {
        value: 'allQuotes',
        key: 'status',
        label: 'All Quotes',
        totalData: 0,
        className: 'brand',
    },
}

const quotesSlice = createSlice({
    name: 'quotes',
    initialState,
    reducers: {
        setData(state, action: PayloadAction<IQuote[]>) {
            const data = action.payload
            state.data = data
        },
        setStatusTotal(state) {
            const statusTotal = {
                allQuotes: state.data.length,
                sea: state.data.filter((x) => x.mode === 'sea').length,
                air: state.data.filter((x) => x.mode === 'air').length,
            }

            state.statusTotal = statusTotal
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            const statusSelected = action.payload
            state.statusSelected = statusSelected
        },
        setDataByStatus(state, action: PayloadAction<IQuote[]>) {
            const data = action.payload
            state.dataByStatus = data
        },
    },
})

// these all the variables used for selector
export const quotesDataSelector = (state: RootState) =>
    state.quotesLimajari.data || {}
export const quotesDataByStatusSelector = (state: RootState) =>
    state.quotesLimajari.dataByStatus || {}
export const statusTotalSelector = (state: RootState) =>
    state.quotesLimajari.statusTotal || {}
export const statusSelectedSelector = (state: RootState) =>
    state.quotesLimajari.statusSelected || {}

// all actions
export const { setStatusTotal, setSelectedStatus, setDataByStatus, setData } =
    quotesSlice.actions

export default quotesSlice.reducer
