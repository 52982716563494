import TabMaster from 'components/tab-master/tab-master.component'
import Table from 'components/table/table.component'
import {
    OrganisationStatusHeaders,
    OrganisationSummaryHeaders,
} from './organisation.static'
import useOrganisation from './organisation.service'
import { IOrganisation } from './organisation.interface'
import { OrganisationDataDummy, metaDummy } from './organisation.dummy'

const Organisation = () => {
    const {
        generateReportModalService,
        tabFilter,
        data,
        importDataButton,
        setTabFilter,
        navigate,
    } = useOrganisation()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    items={OrganisationStatusHeaders}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Code (min 3 char)'}
                    onSearchSubmit={() => {}}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton2={{
                        onClick: () => {},
                        icon: 'ri-filter-2-line',
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate('/create-organisation')
                        },
                        label: 'NEW ORG',
                        className: '!text-size-S',
                    }}
                />

                <Table<IOrganisation>
                    headers={OrganisationSummaryHeaders}
                    data={data || []}
                    loading={false}
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    moduleTitle={'Organisation'}
                    onRowClick={() => {
                        navigate('/organisation-detail')
                    }}
                    enableExport={true}
                    modalService={generateReportModalService}
                    // components={<BookingFilter isGenerateReport={true} />} // there is no filter design yet
                    resetFilter={() => {}}
                    getDataGenerate={() => {}}
                    generateReportData={OrganisationDataDummy}
                    GenerateReportHeaders={OrganisationStatusHeaders}
                    removeFilter={() => {}}
                    additionalButtonBottom={importDataButton}
                />
            </div>
        </div>
    )
}

export default Organisation
