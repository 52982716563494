/* eslint-disable no-unused-vars */
import Input from 'components/input/input.component'
import './date-picker.style.css'
import { formatDate } from 'common/common.service'
import { IDatePickerInput } from './date-picker.interface'

const DatePickerInput = ({
    selectedDate,
    disable,
    selectedRange,
    isRange,
    label,
    onClear,
    setShowDatePicker,
    iconPosition = 'right',
}: IDatePickerInput) => {
    return (
        <div>
            {isRange ? (
                <div
                    className={`flex rounded-[5px] bg-transparent relative gap-2`}
                >
                    <div className="flex w-full relative">
                        <i className="ri-calendar-2-fill absolute left-2 top-[6px] z-[2]"></i>
                        <Input
                            label="Start Date"
                            onClick={() => {
                                setShowDatePicker(true)
                            }}
                            value={
                                selectedRange?.from
                                    ? formatDate(
                                          selectedRange?.from?.toString(),
                                      )
                                    : ''
                            }
                            className={`text-sm placeholder:text-sm !h-[45px] !w-full pl-8 !text-size-M  ${
                                disable
                                    ? 'themes-bg-gray-v5'
                                    : 'themes-bg-white'
                            }`}
                            placeholder={'DD/MM/YYYY'}
                        />
                        {selectedRange?.from && (
                            <i
                                className="ri-close-circle-fill absolute right-3 top-2 cursor-pointer"
                                onClick={onClear}
                            ></i>
                        )}
                    </div>
                    <div className="flex w-full relative">
                        <i className="ri-calendar-2-fill absolute left-2 top-[6px] z-[2]"></i>
                        <Input
                            label="End Date"
                            onClick={() => {
                                setShowDatePicker(true)
                            }}
                            value={
                                selectedRange?.to
                                    ? formatDate(selectedRange.to.toString())
                                    : ''
                            }
                            className={`text-sm placeholder:text-sm !h-[45px] !w-full pl-8 !text-size-M ${
                                disable
                                    ? 'themes-bg-gray-v5'
                                    : 'themes-bg-white'
                            }`}
                            placeholder={'DD/MM/YYYY'}
                        />
                        {selectedRange?.to && (
                            <i
                                className="ri-close-circle-fill absolute right-3 top-2 cursor-pointer"
                                onClick={onClear}
                            ></i>
                        )}
                    </div>
                </div>
            ) : (
                <div className={`flex`}>
                    {iconPosition === 'left' && (
                        <div
                            className="ri-calendar-event-line border-r px-2 my-2 flex items-center mr-[-36px] z-[1] cursor-pointer"
                            onClick={() => {
                                setShowDatePicker(true)
                            }}
                        />
                    )}
                    <Input
                        onChange={() => {}}
                        onClick={() => {
                            setShowDatePicker(true)
                        }}
                        value={
                            selectedDate
                                ? formatDate(selectedDate.toString())
                                : ''
                        }
                        className={`text-sm placeholder:text-sm ${iconPosition === 'left' && 'pl-10'} caret-transparent `}
                        label={label}
                        placeholder={'DD/MM/YYYY'}
                        disabled={disable}
                    />
                    {iconPosition === 'right' && (
                        <div
                            className={`ri-calendar-event-line border-l px-2 my-2 flex items-center ml-[-36px] z-[1]  ${disable ? '' : 'cursor-pointer'} `}
                            onClick={() => {
                                if (!disable) setShowDatePicker(true)
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default DatePickerInput
