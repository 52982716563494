import Button from 'components/button/button.component'
import Modal from 'components/modal/modal.component'
import ContactListForm from './contact-list-form.component'
import { IContactListModalProps } from '../contact-list.interface'

function ContactListModal({
    modalService,
    moduleTitle,
    disable,
    data,
    isCreateAddress,
}: IContactListModalProps) {
    return (
        <Modal
            isModalOpen={modalService.isModalOpen}
            className="!w-1/3 rounded-[10px] !p-0 flex modal-generate-report"
        >
            <div className="flex flex-col gap-3 justify-content w-full">
                <div className="flex font-bold text-size-L justify-between px-4 pt-4">
                    {moduleTitle}
                    <Button
                        className="!w-4 !h-4 !border-0"
                        icon="ri-close-fill"
                        onClick={() => {
                            modalService?.closeModalHandling()
                            // remove temporary filter when close modal
                            // removeFilter()
                        }}
                    />
                </div>
                <div className="border-b border-gray-40"></div>
                <div className="overflow-auto">
                    <ContactListForm
                        disable={disable}
                        moduleTitle={moduleTitle}
                        data={data}
                        modalService={modalService}
                        isCreateAddress={isCreateAddress}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ContactListModal
