import Checkbox from 'components/checkbox/checkbox.component'
import FormDropdown from 'components/form-dropdown/form-dropdown.component'
import FormInput from 'components/form-input/form-input.component'
import FormTextArea from 'components/form-textarea/form-textarea.component'
import ToggleSwitch from 'components/toggle-switch/toggle-switch.component'
import { contactTypeHeaders } from '../../organisation-details/organisation-details.static'

const FormOrganisation = () => {
    const borderComponent = <div className="border-b"></div>
    const contactTypeBorder: string[] = ['payables', 'warehouse', 'storeOrShop']

    return (
        <div className="flex gap-3 h-full">
            <div className="w-3/4 border rounded p-3 flex flex-col gap-3 overflow-auto">
                <div className="">Organisation Details</div>
                <div className="flex gap-x-2">
                    <FormInput
                        label="Organisation Code"
                        name="code"
                        parentDivClassName="w-3/6 !mb-0"
                        placeholder="Enter Code"
                    />
                    <div className="w-5/6">
                        <FormInput
                            label="Full Name"
                            name="fullName"
                            parentInputClassName="w-full"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Full Name"
                        />
                    </div>
                </div>
                <div className="flex gap-x-2">
                    <FormDropdown
                        label="Category"
                        name="category"
                        parentDivClassName="w-3/6 !mb-0 h-[40px]"
                        options={[
                            {
                                label: 'BUS',
                                value: 'Business',
                            },
                            {
                                label: 'GOV',
                                value: 'Government',
                            },
                            {
                                label: 'PER',
                                value: 'Person/Individual',
                            },
                            {
                                label: 'NGO',
                                value: 'Non Government Organisation',
                            },
                        ]}
                        placeholder="Select Category"
                    />
                    <FormInput
                        name="category"
                        parentDivClassName="!mb-0 !w-5/6"
                        placeholder="Category Info"
                        parentInputClassName="w-full"
                        disabled
                    />
                </div>
                <div className="flex gap-x-2">
                    <div className="w-1/2">
                        <FormInput
                            label="Email (Optional)"
                            name="email"
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                        />
                    </div>
                    <div className="w-1/2">
                        <FormInput
                            label="Email Accounting (Optional)"
                            name="emailAccount"
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                        />
                    </div>
                </div>
                <div className="flex gap-x-2">
                    <div className="w-1/2">
                        <FormInput
                            label="Phone Number (Optional)"
                            name="phoneNumber"
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                        />
                    </div>
                    <div className="w-1/2">
                        <FormInput
                            label="Mobile Number (Optional)"
                            name="number"
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                        />
                    </div>
                </div>
                <div className="flex gap-x-2">
                    <div className="w-1/2">
                        <FormInput
                            label="FAX (Optional)"
                            name="fax"
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                        />
                    </div>
                    <div className="w-1/2">
                        <FormInput
                            label="Website URL (Optional)"
                            name="websiteUrl"
                            parentDivClassName="!mb-0"
                            parentInputClassName="w-full"
                        />
                    </div>
                </div>
                <FormDropdown
                    name={'language'}
                    label="Language"
                    placeholder="Select Language"
                    options={[
                        {
                            label: 'English',
                            value: 'English',
                        },
                        {
                            label: 'Indonesian',
                            value: 'Indonesian',
                        },
                    ]}
                />
                <FormTextArea
                    label="Remark (Optional)"
                    name="remark"
                    placeholder="Enter Remark"
                />
                {borderComponent}

                <div>
                    Tax Info
                    <div className="flex gap-3 mt-3">
                        <FormDropdown
                            name={'govermentTaxType'}
                            parentDivClassName="w-1/2"
                            label="Goverment Tax Type"
                            placeholder="Select Goverment Tax Type"
                            options={[
                                {
                                    label: '(ID) INDONESIA',
                                    value: '(ID) INDONESIA',
                                },
                            ]}
                        />
                        <FormDropdown
                            name={'govermentTaxNumber'}
                            parentDivClassName="w-1/2"
                            label="Goverment Tax Number"
                            placeholder="Select Goverment Tax Type"
                            options={[
                                {
                                    label: '52512351512515365',
                                    value: '52512351512515365',
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className="w-1/4 border rounded flex flex-col">
                <div className="p-3">Type</div>
                {borderComponent}
                <div className="p-3 overflow-auto ">
                    <ToggleSwitch
                        initialOn={true}
                        label="Active"
                        onClick={() => {}}
                    />
                    {borderComponent}
                    <div className="grid grid-cols-1 mt-1">
                        {contactTypeHeaders.map((data, idx) => (
                            <div key={`${data.selected}-${idx}`}>
                                <div className="flex justify-between w-full">
                                    <div>{data.label}</div>
                                    <div>
                                        <Checkbox onChecked={() => {}} />
                                    </div>
                                </div>
                                {contactTypeBorder.includes(data.selected) && (
                                    <div className="col-span-2 border-b my-1"></div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormOrganisation
