/* eslint-disable no-unused-vars */
import Button from 'components/button/button.component'
import Input from 'components/input/input.component'
import { ISteps } from 'components/progression-step/progression-step.interface'
import { ISTColumn } from 'components/simple-table/simple-table.interface'
import { ITransportSelectionItem } from 'components/transportation-mode-button/transportation-selection.interface'

export const items: ISteps[] = [
    {
        icon: 'ri-truck-line',
        label: 'Shipment Information',
        value: 'shipmentInformation',
    },
    {
        icon: 'ri-file-list-line',
        label: 'Attach Orders',
        value: 'attachOrders',
    },
    {
        icon: 'ri-box-3-line',
        label: 'Transportation and Goods',
        value: 'transportationAndGoods',
    },
    {
        icon: 'ri-calendar-check-fill',
        label: 'Dates & Instructions',
        value: 'datesInstruction',
    },
]

export const PackLinesHeader: ISTColumn<any>[] = [
    {
        accessor: 'itemName',
        label: 'Item Name',
        customBuild: () => {
            return <Input placeholder='Input Item Name' />
        }

    },
    {
        accessor: 'totalQTy',
        label: 'Total Qty',
        customBuild: () => {
            return <Input placeholder='Input ' />
        }
    },
    {
        accessor: 'price',
        label: 'Price (IDR)',
        customBuild: () => {
            return <Input placeholder='Input Price' />
        }
    },
    {
        accessor: 'totalAmount',
        label: 'Total Amount',
        customBuild: () => {
            return <Input placeholder='Input Total Amount' />
        }
    },
    {
        accessor: 'weight',
        label: 'Weight',
        customBuild: () => {
            return <Input placeholder='Input Weight' />
        }
    },
    {
        accessor: 'id',
        label: 'Action',
        customBuild: () => (
            <div className="pr-4 flex items-center gap-x-2">
                <Button
                    onClick={() => { }}
                    variant="red-inverse"
                    icon="ri-delete-bin-line !p-0"
                    className={`!p-0  !h-[1.8rem] !w-[55px]`}
                />
            </div>
        ),
    },
]

export const DocumentsHeader = [
    {
        accessor: 'actions',
        label: 'Actions',
    },
    {
        accessor: 'fileName',
        label: 'File Name',
    },
    {
        accessor: 'description',
        label: 'Description',
    },
    {
        accessor: 'dateAdded',
        label: 'Date Added',
    },
    {
        accessor: 'id',
        label: 'Action',
        customBuild: () => (
            <div className="pr-4 flex items-center gap-x-2">
                <Button
                    onClick={() => { }}
                    variant="red-inverse"
                    icon="ri-delete-bin-line !p-0"
                    className={`!p-0  !h-[1.8rem] !w-[55px]`}
                />
                <Button
                    onClick={() => { }}
                    variant="default"
                    icon="ri-edit-2-line !p-0"
                    className={`!p-0 !h-[1.8rem] !w-[55px]`}
                />
            </div>
        ),
    },
]


export const TransportationSelectionItems: ITransportSelectionItem[] = [
    {
        icon: 'ri-plane-line',
        label: 'AIR FREIGHT',
        description: 'Air cargo, is the transportation of goods by air using airplanes',
    },
    {
        icon: 'ri-ship-line',
        label: 'SEA FREIGHT',
        description: 'Involves transporting goods by ships or vessels over oceans',
    },
    {
        icon: 'ri-truck-line',
        label: 'ROAD FREIGHT',
        description: 'Utilizes trucks or lorries for goods transportation over land',
    },
    {
        icon: 'ri-train-line',
        label: 'RAIL FREIGHT',
        description: 'Involves using trains for efficient overland transportation',
    },
    {
        icon: 'ri-apps-2-line',
        label: 'INTERMODAL',
        description: `Combines various modes (e.g., air, sea, road)`,
    },
]
