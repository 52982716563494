/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { quoteSummaryPillHeaders } from './new-quote.static'
import { useFormik } from 'formik'
import {
    INewQuote,
    newQuoteInitialValue,
    newQuoteValidation,
} from 'form-validation/limajari/new-quote.validation'
import { ISTColumn } from 'components/simple-table/simple-table.interface'
import { IServiceRate, IShippingRate } from './new-quote.interface'
import FormInput from 'components/form-input/form-input.component'
import FormDropdown from 'components/form-dropdown/form-dropdown.component'
import Button from 'components/button/button.component'
import Dropdown from 'components/dropdown/dropdown.component'
import { numberWithCommas } from 'common/common.service'
import { quoteDetailsDummy, shippingModes } from './new-quote.dummy'
import OptionShippingMode from './components/option-shipping-mode'
import ChargeList from './contents/charge-list.component'
import FormTextArea from 'components/form-textarea/form-textarea.component'
import { Toast } from 'components/toast/toast.component'
import DuplicateQouteModal from './components/duplicate-quote-modal.component'
import { useModal } from 'components/modal/modal.service'

const useNewQuote = (isDetailPage: boolean) => {
    const navigate = useNavigate()

    // states
    const [pillItems, setPillItems] = useState(quoteSummaryPillHeaders[0])

    const duplicateQuoteModal = useModal()

    // formik
    const formik = useFormik<INewQuote>({
        validationSchema: newQuoteValidation,
        initialValues: newQuoteInitialValue,
        onSubmit: (values) => {
            const button = document.getElementById('quote-preview-document')
            button?.click()
            Toast({
                header: 'Sucess',
                message: 'Successful generate quote',
                type: 'success',
            })
            navigate('/quotes')
        },
    })

    // useEffect
    useEffect(() => {
        if (isDetailPage) formik.setValues(quoteDetailsDummy)
    }, [isDetailPage])

    const shippingRateHeaders: ISTColumn<IShippingRate>[] = [
        {
            accessor: 'shippingMode',
            label: 'Shipping Mode',
            customBuild: (data, func, idx) => {
                return (
                    <FormDropdown
                        options={shippingModes}
                        dropDownIndicator={true}
                        isClearable={true}
                        placeholder="Select Title"
                        name={`serviceRates[${idx}].title`}
                        parentDivClassName="w-[362px] !mb-0 flex items-center"
                        customOptionComponent={OptionShippingMode}
                        disabled={isDetailPage}
                    />
                )
            },
            isComponent: true,
        },
        {
            accessor: 'price',
            label: 'Price/unit',
            customBuild: (data, func, idx) => (
                <FormInput
                    name={`serviceRates[${idx}].price`}
                    parentDivClassName="!mb-0 w-[120px]"
                    disabled
                    descriptionValue="IDR"
                />
            ),
            isComponent: true,
        },
        {
            accessor: 'unit',
            label: 'Unit',
            customBuild: (data, func, idx) => (
                <FormInput
                    name={`serviceRates[${idx}].unit`}
                    parentDivClassName="!mb-0 w-[160px]"
                    disabled={isDetailPage}
                />
            ),
            isComponent: true,
        },
        {
            accessor: 'qty',
            label: 'Qty',
            customBuild: (data, func, idx) => (
                <FormInput
                    name={`serviceRates[${idx}].qty`}
                    parentDivClassName="!mb-0 w-[120px]"
                    disabled={isDetailPage}
                />
            ),
            isComponent: true,
        },
        {
            accessor: 'total',
            label: 'Total',
            customBuild: (data, func, idx) => (
                <FormInput
                    name={`serviceRates[${idx}].total`}
                    parentDivClassName="!mb-0 w-[200px]"
                    disabled
                    descriptionValue="IDR"
                />
            ),
            isComponent: true,
        },
    ]

    const serviceRateHeaders: ISTColumn<IServiceRate>[] = [
        {
            accessor: 'title',
            label: 'Title',
            customBuild: (data, func, idx) => {
                return (
                    <FormDropdown
                        options={[
                            {
                                label: 'Handling',
                                value: 'Handling',
                            },
                            {
                                label: 'Packing Wooden Crate',
                                value: 'Packing Wooden Crate',
                            },
                        ]}
                        dropDownIndicator={true}
                        isClearable={true}
                        placeholder="Select Title"
                        name={`serviceRates[${idx}].title`}
                        parentDivClassName="w-[320px] !mb-0 flex items-center"
                        className="w-full"
                        disabled={isDetailPage}
                    />
                )
            },
            isComponent: true,
        },
        {
            accessor: 'price',
            label: 'Price/unit',
            customBuild: (data, func, idx) => (
                <FormInput
                    name={`serviceRates[${idx}].price`}
                    parentDivClassName="!mb-0"
                    disabled
                />
            ),
            isComponent: true,
        },
        {
            accessor: 'unit',
            label: 'Unit',
            customBuild: (data, func, idx) => (
                <FormInput
                    name={`serviceRates[${idx}].unit`}
                    parentDivClassName="!mb-0"
                    disabled={isDetailPage}
                />
            ),
            isComponent: true,
        },
        {
            accessor: 'qty',
            label: 'Qty',
            customBuild: (data, func, idx) => (
                <FormInput
                    name={`serviceRates[${idx}].qty`}
                    parentDivClassName="!mb-0"
                    disabled={isDetailPage}
                />
            ),
            isComponent: true,
        },
        {
            accessor: 'total',
            label: 'Total',
            customBuild: (data, func, idx) => (
                <FormInput
                    name={`serviceRates[${idx}].total`}
                    parentDivClassName="!mb-0"
                    disabled
                />
            ),
            isComponent: true,
        },
        {
            accessor: 'id',
            label: '',
            customBuild: (data, func, idx, x, rowData) => {
                if (!rowData) <></>
                return (
                    <Button
                        onClick={() =>
                            rowData && deleteServiceRateById(rowData?.id)
                        }
                        icon="ri-delete-bin-line"
                        variant="red-inverse"
                        className="w-btnIconWidth !h-auto"
                        isDisabled={isDetailPage}
                    />
                )
            },
            isComponent: true,
        },
    ]

    const deleteServiceRateById = (id: string) => {
        const serviceRateData = formik.values.serviceRates
        const updateData = serviceRateData.filter((item) => item.id !== id)
        formik.setValues((state) => ({ ...state, serviceRates: updateData }))
    }

    const addNewRow = () => {
        const serviceRateData = formik.values.serviceRates
        const newServiceRate: IServiceRate = {
            id: `${serviceRateData.length + 1}`,
            title: '',
            price: '',
            unit: '',
            qty: '',
            total: '0',
        }
        formik.setValues((state) => ({
            ...state,
            serviceRates: [...serviceRateData, newServiceRate],
        }))
    }

    const totalServiceRate = formik.values.serviceRates.reduce((sum, item) => {
        // Parse the total value as a number
        const totalValue = parseFloat(
            item.total.replace('.', '').replace(',', '.'),
        )
        return sum + totalValue
    }, 0)

    const duplicateQuoteSubmit = () => {
        Toast({
            header: 'Sucess',
            message: 'Successful duplicate data quote',
            type: 'success',
        })
        duplicateQuoteModal.closeModalHandling()
        navigate('/quotes')
    }

    // component
    const getContentQuoteSummary = () => {
        const contents: Record<string, JSX.Element> = {
            chargeList: (
                <ChargeList
                    shippingRateData={formik.values.shippingRates}
                    shippingRateHeaders={shippingRateHeaders}
                    shippingRateFooter={footerShippingRateTable}
                    serviceRateData={formik.values.serviceRates}
                    serviceRatetitle={serviceRateTitle}
                    serviceRateHeaders={serviceRateHeaders}
                    serviceRateFooter={serviceRateFooter}
                    isDetailPage={isDetailPage}
                />
            ),
            notes: (
                <FormTextArea
                    name={'importantNotice'}
                    label="Important Notice"
                    parentDivClassName="h-[165px]"
                    disabled={isDetailPage}
                    disabledVariant="white"
                />
            ),
        }
        return contents[pillItems.accessor]
    }
    const serviceRateTitle = (
        <div className="flex justify-between">
            <div>
                <Dropdown
                    options={[
                        {
                            label: 'SERVICE RATES 1',
                            value: 'SERVICE RATES 1',
                        },
                        {
                            label: 'SERVICE RATES 2',
                            value: 'SERVICE RATES 2',
                        },
                        {
                            label: 'SERVICE RATES (CUSTOM)',
                            value: 'SERVICE RATES (CUSTOM)',
                        },
                    ]}
                    defaultValue={[
                        {
                            label: 'SERVICE RATES 1',
                            value: 'SERVICE RATES 1',
                        },
                    ]}
                    dropDownIndicator
                    useBorder={false}
                    disabled={isDetailPage}
                />
            </div>
            <Button
                onClick={() => addNewRow()}
                icon="ri-add-line"
                label="ADD LIST"
                className="border-none"
                isDisabled={isDetailPage}
            />
        </div>
    )
    const serviceRateFooter = (
        <div className="flex justify-between">
            <div>
                TOTAL FOR <span className="themes-text-brand-v5">1 CBM</span> IS
            </div>
            <div className="themes-text-brand-v5">
                IDR {numberWithCommas(totalServiceRate.toString())}
            </div>
        </div>
    )
    const footerShippingRateTable = (
        <div className="flex justify-between">
            <div>
                TOTAL FOR <span className="themes-text-brand-v5">1 CBM</span> IS
            </div>
            <div className="themes-text-brand-v5">
                IDR {formik.values.shippingRates.total || '0'}
            </div>
        </div>
    )
    const mallModal = (
        <>
            <DuplicateQouteModal
                modalService={duplicateQuoteModal}
                onSubmit={duplicateQuoteSubmit}
            />
        </>
    )
    const buttonAction = (
        <Button
            onClick={() => {
                if (isDetailPage) {
                    duplicateQuoteModal.openModalHandling()
                } else {
                    formik.submitForm()
                }
            }}
            icon={isDetailPage ? 'ri-file-copy-line' : 'ri-download-2-line'}
            label={isDetailPage ? 'DUPLICATE & NEW' : 'GENERATE QUOTE'}
            variant={isDetailPage ? 'default' : 'brand'}
        />
    )

    return {
        navigate,
        setPillItems,
        addNewRow,
        deleteServiceRateById,
        getContentQuoteSummary,
        buttonAction,
        mallModal,
        footerShippingRateTable,
        shippingRateheaders: shippingRateHeaders,
        formik,
        pillItems,
    }
}

export default useNewQuote
