import Button from "components/button/button.component"
import Dropdown from "components/dropdown/dropdown.component"
import Input from "components/input/input.component"
import { IUseUserAccessForm } from "pages/common/user-acces/user-access-form/user-access-form.service"
import "./manage-user-form.style.css"
import useManageUserForm from "./manage-user-form.service"

const ManageUserForm = ({ isNew, isProfilePage }: IUseUserAccessForm) => {
    const { organization, roleDataDropdown, gender, navigate } = useManageUserForm()

    const labelNew = isNew ? 'CREATE USER' : 'UPDATE USER'
    const label = isProfilePage ? 'USER DETAIL' : labelNew
    const isDisabled = !isProfilePage ? false : true
    const buttonLabel = isNew ? 'CREATE USER' : 'UPDATE USER'
    const cancelButtonLabel = isNew ? 'CANCEL CREATE' : 'CANCEL UPDATE'

    return (
        <>
            <div
                className={`container-global create-user transition-opacity duration-500 `}
            >
                <div className="border-b border-logistical-gray-ver7 ">
                    <div className="p-4 text-size-S font-bold">{label}</div>
                </div>

                {/* Form */}
                <div className="content">
                    <div className="content-flex content-flex-1">
                        <div className="text-size-S font-bold mb-3">
                            User Profile
                        </div>
                        <Input
                            disabled={isDisabled}
                            label="FULL NAME"
                            placeholder="Enter full name"
                            name={'fullName'}
                            required
                        />
                        <Input
                            disabled={isDisabled}
                            label="JOB TITLE"
                            placeholder="Enter job title"
                            name={'jobTitle'}
                            required
                        />
                        <Input
                            disabled={isDisabled}
                            label="JOB CATEGORY"
                            placeholder="Enter job category"
                            name={'jobCategory'}
                            required
                        />
                        <Dropdown
                            placeholder="Select gender"
                            label="GENDER"
                            options={gender}
                            dropDownIndicator={true}
                        />

                        <div className="text-size-S font-bold mb-3 mt-3">
                            User Address
                        </div>
                        <Input
                            disabled={isDisabled}
                            label="COUNTRY"
                            placeholder="Enter country"
                            name={'country'}
                            required
                        />
                        <Input
                            disabled={isDisabled}
                            label="CITY"
                            placeholder="Enter city"
                            name={'city'}
                            required
                        />
                        <Input
                            disabled={isDisabled}
                            label="ADDRESS DETAIL"
                            placeholder="Enter address"
                            name={'address'}
                            required
                        />

                        <div className="flex gap-2 flex-wrap mt-4">
                            <div className="flex-1 flex flex-col space-y-3">
                                <div className="text-size-S font-bold ">
                                    User Contact
                                </div>

                                <Input
                                    disabled={isDisabled}
                                    label="MOBILE PHONE NUMBER"
                                    placeholder="Enter mobile phone"
                                    name={'mobilePhone'}
                                />
                                <Input
                                    disabled={isDisabled}
                                    label="WORK PHONE NUMBER"
                                    placeholder="Enter work phone"
                                    name={'workPhone'}
                                />
                                <Input
                                    disabled={isDisabled}
                                    label="HOME PHONE"
                                    placeholder="Enter home phone"
                                    name={'homePhone'}
                                />
                            </div>

                            <div className="flex-1 flex flex-col space-y-3">
                                <div className="text-size-S font-bold">
                                    Emergency Contact
                                </div>

                                <Input
                                    disabled={isDisabled}
                                    label="EMERGENCY CONTACT NAME"
                                    placeholder="Enter emergency contact name"
                                    name={'emergencyContactName'}
                                />
                                <Input
                                    disabled={isDisabled}
                                    label="EMERGENCY HOME PHONE"
                                    placeholder="Enter emergency phone"
                                    name={'emergencyHomePhone'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="content-flex content-flex-2">
                        <div className="text-size-S font-bold mb-3">
                            User Account
                        </div>

                        {isProfilePage ? (
                            <Input
                                disabled={isDisabled}
                                label="Organisation"
                                placeholder="Enter Role"
                                name={'organizationLabel'}
                                required
                            />
                        ) : (
                            <Dropdown
                                disabled={isDisabled}
                                label="ORGANISATION"
                                placeholder="Select organisation"
                                required
                                options={organization}
                                dropDownIndicator
                            />
                        )}

                        <Input
                            disabled={isDisabled}
                            label="EMAIL"
                            placeholder="Enter email"
                            name={'portalEmail'}
                            required
                        />
                        <Input
                            disabled={isDisabled}
                            label="USERNAME"
                            placeholder="Enter username"
                            name={'portalLogin'}
                            required
                            autoComplete="new-username"
                            onFocus={(event) => {
                                event.target.setAttribute(
                                    'autocomplete',
                                    'off',
                                )
                                console.log(event.target.autocomplete)
                            }}
                        />

                        {!isNew ? (
                            <></>
                        ) : (
                            <Input
                                disabled={isDisabled}
                                label="PASSWORD"
                                placeholder="Enter password"
                                name='password'
                                type='password'
                                required
                                autoComplete="asdsd"
                            />
                        )}
                        {isProfilePage ? (
                            <Input
                                disabled={isDisabled}
                                label="ROLE"
                                placeholder="Enter Role"
                                name={'roleDropdown'}
                                required
                            />
                        ) : (
                            <Dropdown
                                label="ROLE"
                                placeholder="Select role"
                                required
                                options={roleDataDropdown}
                                dropDownIndicator
                            />
                        )}

                        {isProfilePage ? (
                            <>
                                <div className="text-size-S font-bold mb-3">
                                    Update Password
                                </div>
                                <Input
                                    label="Current Password"
                                    placeholder="Enter Current Password"
                                    name={'currentPassword'}
                                    type="password"
                                    required
                                />
                                <Input
                                    label="New Password"
                                    placeholder="Enter New Password"
                                    name={'newPassword'}
                                    required
                                    autoComplete="disabled"
                                    type="password"
                                />
                                <Input
                                    label="Re type New Password"
                                    placeholder="Retype New Password"
                                    name={'newPasswordConfirmation'}
                                    required
                                    autoComplete="disabled"
                                    type="password"
                                />
                                <div className="flex justify-end">
                                    <Button
                                        type="button"
                                        onClick={() => { }}
                                        label={'UPDATE PASSWORD'}
                                    />
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>

                {/* Button */}
                <div className="flex justify-between p-4">
                    {!isProfilePage ? (
                        <Button
                            onClick={() => {
                                navigate(-1)
                            }}
                            label={cancelButtonLabel}
                        />
                    ) : null}
                    {!isProfilePage ? (
                        <Button
                            type="button"
                            onClick={() => {
                                navigate('/manage-users')
                            }}
                            label={buttonLabel}
                        />
                    ) : null}
                </div>

                {/* <SelectModuleModal
                    modalService={modalService}
                    moduleList={moduleAdjustmentList}
                    setModalSelectedPhase1={(selected) => {
                        setModalSelectedPhase1(selected)
                    }}
                    setModalSelectedPhase2={(selected) => {
                        setModalSelectedPhase2(selected)
                    }}
                    setAccessHandling={(indexAction: number) => {
                        setAccess(indexAction)
                    }}
                    modalSelectedPhase1={modalSelectedPhase1}
                    modalSelectedPhase2={modalSelectedPhase2}
                    setAllAccess={() => {
                        setAllAccess({})
                    }}
                /> */}
            </div>
        </>
    )
}

export default ManageUserForm